import {
    ADD_ADMIN,
    DELETE_ADMIN,
    EDIT_ADMIN,
    SET_ADMINS, 
    FAIL_SET_ADMIN,
    OPEN_MODAL,
    CLOSE_MODAL,
    FAIL_ADD_ADMIN,
    SUCCESS_ADD_ADMIN
  } from './types';
  
 import axios from 'axios';
import { failSetUsers } from '../users/action';
  
  export const setAdmins = (admin , total) => {
          return {
              type: SET_ADMINS,
              admins: admin
  }};
  export const failSetAdmins = () => {
    return{
      type : FAIL_SET_ADMIN
    }
  }
  export const openModal = () => {
    return{
      type : OPEN_MODAL
    }
  }
  export const closeModal = () => {
    return{
      type : CLOSE_MODAL
    }
  }
  export const failAddAdmin = (error) => {
    return{
      type : FAIL_ADD_ADMIN,
      error : error
    }
  }
  export const sucessAddAdmin = () => {
    return{
      type : SUCCESS_ADD_ADMIN
    }
  }
  export const getAdmins= (page) => {
    return dispatch => {
        axios({
            method : "GET",
            url : '/u-consultation/superadmin/list/users',
            params : {page : page, limit : 10 ,role : 'admin'},
            headers : {Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json",}
        }).then((resp)=> {
            console.log(resp)
            dispatch(setAdmins(resp.data.docs , resp.data.totalDocs));
        }).catch((err) =>{
         dispatch(failSetUsers())
        })
    }
}
  export const AddAdmin = (data) => {
   return dispatch => {
     axios({
       method : "POST",
       url : '/u-consultation/superadmin/add/admin',
       data : {...data},
       headers : {Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json",}
     }).then((res) => {
       dispatch(closeModal())
     }).catch((err) =>{
     dispatch(failAddAdmin(err.response.data.message))
     })
    }
  }