import React from 'react';
import { Button, Input, Collapse, Form, Alert, InputNumber, Row, Col } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect, useDispatch } from 'react-redux';
import { addIcd } from '../../../redux/icd/actions';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { emptyIcd11, Icd11Keys } from '../common/icd11Types';
import JoditEditor from 'jodit-react';

const { Panel } = Collapse;
const { Item } = Form;

const { TextArea } = Input;

const AddIcd11Form = ({ icd11 = emptyIcd11[0] }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    // console.log(form.getFieldsValue())
    form
      .validateFields()
      .then(() => dispatch(addIcd(form.getFieldsValue())))
      .catch(() => null);
  };
  const history = useHistory();
  const handleCancel = () => {
    history.goBack();
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean']
    ]
  };
  return (
    <>
      <Form form={form} onFinish={handleSubmit} initialValues={icd11} layout='vertical'>
        <Collapse>
          {Icd11Keys.map((item) => {
            return (
              <Panel header={item} key={item}>
                <div className='form-group'>
                  {item === 'Male' ||
                  item === 'Female' ||
                  item === 'Poisoning' ||
                  item === 'Accident' ||
                  item === 'ChronicDisease' ||
                  item === 'RiskIndex' ||
                  item === 'fetus_first' ||
                  item === 'fetus_secand' ||
                  item === 'fetus_third' ||
                  item === 'newborn' ||
                  item === 'm1_y5' ||
                  item === 'y5_15' ||
                  item === 'y15_25' ||
                  item === 'y25_45' ||
                  item === 'above65y' ? (
                    <Form.Item name={item} noStyle>
                      <InputNumber
                        max={item === 'RiskIndex' ? 10 : 1}
                        min={0}
                        style={{ width: '100%', marginBottom: '10px' }}
                        placeholder={item}
                        maxLength={1}
                      />
                    </Form.Item>
                  ) : (
                    <Item name={item}>
                      <JoditEditor value='' />
                    </Item>
                  )}
                </div>
              </Panel>
            );
          })}
        </Collapse>

        <div
          className='d-flex justify-content-between buttons-list settings-actions'
          style={{ width: '100%' }}
        >
          <Button type='link' onClick={handleCancel}>
            go back
          </Button>

          <Button type='primary' htmlType='submit'>
            add Icd
          </Button>
        </div>
      </Form>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.users.error
  };
};

export default connect(mapStateToProps)(AddIcd11Form);
