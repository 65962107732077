export const IcdKeys = [
  'GeneralSymptoms',
  'SpecificSymptoms',
  'ListOfReferences',
  'RiskIndex',
  'ParentBillableCode',
  'ChildBillableCode',
  'DiagnosisVariances',
  'ClincalSigns',
  'LaboratoryDiagnosis',
  'RadiologyDiagnosis',
  'DiagnosticTest',
  'WhenToSeeDoctor',
  'LaboratoryInvestigations',
  'RadiologyScans',
  'PlanOfCare',
  'RecommendedMedications',
  'RecommendedTherapy',
  'MedicalAndNursingRecommendations',
  'PrecautionsAndPrevention',
  'AssociatedMedicalConditions',
  'PregnancySpecialPrecautions',
  'ChapterCode',
  'ParentCategory',
  'ChildCategory',
  'ParentCode',
  'ChildCode',
  'RelatedSpecialty',
  'Diagnosis',
  'ChronicDisease',
  'Accident',
  'Poisoning',
  'Male',
  'Female',
  'AgeGroup'
];

export const emptyIcd = {
  ICDVersion: null,
  System: null,
  ParentBillableCode: null,
  ChildBillableCode: null,
  DiagnosisVariances: null,
  GeneralSymptoms: null,
  SpecificSymptoms: null,
  ClincalSigns: null,
  LaboratoryDiagnosis: null,
  RadiologyDiagnosis: null,
  DiagnosticTest: null,
  WhenToSeeDoctor: null,
  LaboratoryInvestigations: null,
  RadiologyScans: null,
  PlanOfCare: null,
  RecommendedMedications: null,
  RecommendedTherapy: null,
  MedicalAndNursingRecommendations: null,
  PrecautionsAndPrevention: null,
  AssociatedMedicalConditions: null,
  PregnancySpecialPrecautions: null,
  _id: null,
  ChapterCode: null,
  ParentCategory: null,
  ChildCategory: null,
  ParentCode: null,
  ChildCode: null,
  Diagnosis: null,

  ListOfReferences: null,

  AgeGroup: []
};

export const AgeGroup = [
  'FetusFirstTrimester',
  'FetusSecondTrimester',
  'FetusThirdTrimester',
  'FetusPostTerm',
  'Newborn',
  'm1_5y',
  'y5_15',
  'y15_25',
  'y25_45',
  'y45_65',
  'above65y'
];
