import React from 'react';
import {
  Button,
  Input,
  Collapse,
  Form,
  Alert,
  InputNumber,
  Row,
  Col,
  Select,
  notification
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect, useDispatch } from 'react-redux';
import { addIcd } from '../../../redux/icd/actions';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { commonKeys } from '../common/commonDiagnosistypes';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CommonForm from './form';
import Axios from 'axios';

const { Panel } = Collapse;
const { Item } = Form;

const CommonDiagnosisForm = () => {
  const pageData: IPageData = {
    title: ` Add common Diagnosis`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'Add common Diagnosis'
      }
    ]
  };
  usePageData(pageData);

  const [form] = useForm();
  const handleSubmit = (values) => {
    console.log(form.getFieldsValue());
    form.validateFields().then(() => {
      Axios({
        method: 'POST',
        url: `u-consultation/commondiagnosis`,
        data: { ...values },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }).then(() => {
        notification.info({
          message: `succeful added`,
          placement: 'bottomRight'
        });
      });
    });
  };
  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <CommonForm />
      </Form>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.users.error
  };
};

export default connect(mapStateToProps)(CommonDiagnosisForm);
