import React, { useState, useEffect } from 'react';
import { Button, Input, Select, Form, Alert, Row, Col, Switch, Checkbox } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { editUser } from '../../../redux/udeUser/action';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import Specialtyinput from '../../components/specialtyInput/specialtyinput';

const FormItem = Form.Item;
const Option = Select.Option;

const children = [
  <Option value='clinic'> clinic </Option>,
  <Option value='medical center'> medical center </Option>,
  <Option value='pharmacy'> pharmacy </Option>
];
const emptyUser = {
  isVerified: null,
  countryCode: null,
  isActive: null,
  isProvider: null,
  fullName: null,
  _id: null,
  username: null,
  phone: null,
  pinCode: null,
  speciality: '6190c8da4d4d8d08fcf545e5',
  linkedAccounts: [],
  __v: 0
};
const UdeUsersForm = (props) => {
  const [user, setUser] = useState(emptyUser);
  const pageData: IPageData = {
    title: `ude-user :  ${user.fullName}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'ICD 10'
      }
    ]
  };
  usePageData(pageData);

  const [form] = useForm();
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    Axios({
      method: 'GET',
      url: `/u-consultation/ude-user/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      form.setFieldsValue(res.data);
      setUser(res.data);
    });
  }, []);
  const handleSubmit = (values) => {
    // console.log(form.getFieldsValue());
    form
      .validateFields()
      .then(() => dispatch(editUser(user._id, form.getFieldsValue())))
      .catch(() => null);
  };
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };
  const handleverfiedChange = () => {
    // props.onswitch(user._id , !user.isActive)
    // setverfived(!verfived);
    setUser({
      ...user,
      isVerified: !user.isVerified
    });
  };
  const handleActiveChange = () => {
    // props.onswitch(user._id , !user.isActive)
    // setActive(!active);
    setUser({
      ...user,
      isActive: !user.isActive
    });
  };
  return (
    <div>
      <Form form={form} layout='vertical' initialValues={user}>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col span={11}>
            <FormItem name='fullName' label='Full name'>
              <Input placeholder='Full name' />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name='phone' label='Phone'>
              <Input placeholder='Phone' readOnly />
            </FormItem>
          </Col>
          <Col span={3}>
            <FormItem name='isVerified' label='isVerified'>
              <Switch checked={user.isVerified} onChange={handleverfiedChange} />
            </FormItem>
          </Col>
          {user.speciality ? (
            <Col span={11}>
              <Specialtyinput />
            </Col>
          ) : null}
          <Col span={8}>
            <FormItem name='username' label='username'>
              <Input placeholder='username' />
            </FormItem>
          </Col>

          <Col span={3}>
            <FormItem name='isActive' label='Status'>
              <Switch checked={user.isActive} onChange={handleActiveChange} />
            </FormItem>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col span='11'>
            <Button type='link' onClick={handleBack}>
              {' '}
              Back{' '}
            </Button>
          </Col>
          <Col span='11'>
            <Button
              type='primary'
              htmlType='submit'
              onClick={handleSubmit}
              style={{ marginLeft: '80%' }}
            >
              save Change
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UdeUsersForm;
