import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import axios from 'axios';
const { Option } = Select;
export default function Specialtyinput(props) {
  const [specialty, setSepcialty] = useState([]);
  useEffect(() => {
    axios({
      method: 'GET',
      url: 'u-consultation/speciality',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      setSepcialty(response.data.data);
    });
  }, []);

  function onSearchSelect(val) {
    console.log(val);
    const text = document.querySelectorAll('p.specialtyName');
    for (let i = 0; i < text.length; i++) {
      const originalText = text[i].textContent;
      let word = val.trim();
      word = word.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      const regexp = new RegExp(word, 'gi');
      text[i].innerHTML = originalText.replace(regexp, `<span class = "bold">${word}</span>`);
      console.log(text[i]);
    }
  }
  const SpecialtyOption = specialty.map((item) => {
    return (
      <Option value={item._id} key={item.mainSpeciality.en}>
        <div>
          <p className='specialtyName'>{item.mainSpeciality.en} </p>
        </div>
      </Option>
    );
  });
  return (
    <Form.Item name='speciality' label='specialty'>
      <Select
        className='specialty'
        disabled={props.disabled}
        dropdownClassName='specialtyDropdwonRegister'
        onSearch={onSearchSelect}
        optionFilterProp='children'
        showSearch
        placeholder='please select your main'
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value='all'>All</Option>
        {SpecialtyOption}
      </Select>
      {/* <h3 className="speciality-label">Main speciality</h3> */}
    </Form.Item>
  );
}
