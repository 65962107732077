import React, { useEffect, useState } from 'react';
import { Button, Input, InputNumber, Form, Collapse, Modal, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router';
import { editIcd } from '../../../redux/icd/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IcdCart from './Icdcart/icdCart';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import axios from 'axios';
import { show } from '../../../redux/icd/actions';
import JoditEditor from 'jodit-react';
import FullPageLoading from '../../components/FullPageLoading';
import { AgeGroup, emptyIcd, IcdKeys } from '../common/icd10typs';
import Goback from '../../../layout/components/goBack/goback';
const { Panel } = Collapse;
const { Item } = Form;

const IcdForm = () => {
  const pageData: IPageData = {
    title: `Icd  - page (last page you visit) : ${localStorage.getItem('icdPage')} `,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'ICD 10'
      }
    ]
  };
  const [icd, setIcd] = useState(emptyIcd);
  const [loading, setloading] = useState(true);
  const params: { id: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    axios({
      method: 'GET',
      url: `u-consultation/icd/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log('icd res', res.data.data);
      setIcd({ ...res.data.data });
      icdForm.setFieldsValue(res.data.data);
      setloading(false);
    });
  }, []);

  usePageData(pageData);
  const [icdForm] = useForm();
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    icdForm
      .validateFields()
      .then(() => dispatch(editIcd(values._id, icdForm.getFieldsValue())))
      .catch(() => null);
  };

  const { Search } = Input;

  const handleSearch = (value) => {
    axios({
      method: 'GET',
      url: 'u-consultation/icd',
      params: { ChildCode: value }
    }).then((res) => {
      setIcd(res.data.docs[0]);
      icdForm.setFieldsValue(res.data.docs[0]);
    });
  };
  const DeletIcd = () => {
    axios({
      method: 'DELETE',
      url: `u-consultation/icd/${icd._id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
      .then(() => {
        notification.success({
          placement: 'bottomRight',
          description: 'deleted',
          message: 'this icd is deleted for ever'
        });
      })
      .then(() => {
        history.goBack();
      })
      .catch((err) => {
        notification.error({
          placement: 'bottomRight',
          description: 'some thing went wrong',
          message: 'please try again later'
        });
      });
  };
  const handleDeleteIcd = () => {
    Modal.warning({
      title: `do you need to delete this Icd ${icd.ChildCode}`,
      content: 'by click on delete you will loose this icd forever be carful please',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => DeletIcd(),
      okCancel: true,
      cancelText: 'Cancel'
    });
  };
  return (
    <>
      {loading ? (
        <>
          <FullPageLoading />{' '}
        </>
      ) : (
        <div>
          <IcdCart result={icd} />
          <Form form={icdForm} onFinish={handleSubmit} initialValues={icd} layout='vertical'>
            <Form.Item label='_id' name='_id' style={{ display: 'none' }}>
              <Input type='text' />
            </Form.Item>
            <div
              className='d-flex justify-content-between buttons-list settings-actions'
              style={{ width: '100%', marginBottom: '20px' }}
            >
              <Goback text='goback' />

              <Button className='updateButton' onClick={() => icdForm.submit()}>
                update Icd - <span> {icd.ChildCode} </span>
              </Button>
              <Search
                className='icd-search'
                enterButton
                onSearch={handleSearch}
                style={{ width: '200px' }}
                placeholder='write child code'
              />
              <Button className='deleteButton' danger onClick={handleDeleteIcd}>
                Delete Icd - <span> {icd.ChildCode} </span>
              </Button>
            </div>

            <Collapse
              defaultActiveKey={[
                'GeneralSymptoms',
                'SpecificSymptoms',
                'ListOfReferences',
                'RiskIndex'
              ]}
            >
              {IcdKeys.map((item) => {
                return (
                  <Panel header={item} key={item}>
                    <div className='form-group'>
                      {item === 'AgeGroup' ? (
                        <Form.Item>
                          <Input.Group compact>
                            <Collapse style={{ width: '100%' }}>
                              {AgeGroup.map((categoriy) => {
                                return (
                                  <Panel header={categoriy} key={categoriy}>
                                    <Form.Item name={['ageGroup', categoriy]} noStyle>
                                      <InputNumber
                                        max={1}
                                        min={0}
                                        style={{ width: '100%', marginBottom: '10px' }}
                                        placeholder={categoriy}
                                        maxLength={1}
                                      />
                                    </Form.Item>
                                  </Panel>
                                );
                              })}
                            </Collapse>
                          </Input.Group>
                        </Form.Item>
                      ) : item === 'ChildCode' ? (
                        <Form.Item name={item}>
                          <Input type='text' />
                        </Form.Item>
                      ) : item === 'Male' ||
                        item === 'Female' ||
                        item === 'Poisoning' ||
                        item === 'Accident' ||
                        item === 'ChronicDisease' ||
                        item === 'RiskIndex' ? (
                        <Form.Item name={item} noStyle>
                          <InputNumber
                            max={item === 'RiskIndex' ? 10 : 1}
                            min={0}
                            style={{ width: '100%', marginBottom: '10px' }}
                            placeholder={item}
                            maxLength={1}
                          />
                        </Form.Item>
                      ) : (
                        <Item name={item}>
                          <JoditEditor value='' />
                        </Item>
                      )}
                    </div>
                  </Panel>
                );
              })}
            </Collapse>
          </Form>
        </div>
      )}
    </>
  );
};

export default IcdForm;

// [
// "ICDVersion",
// "System",
// "ParentBillableCode",
// "ChildBillableCode",
// "DiagnosisVariances",
// "GeneralSymptoms",
// "SpecificSymptoms",
// "ClincalSigns",
// "LaboratoryDiagnosis",
// "RadiologyDiagnosis",
// "DiagnosticTest",
// "WhenToSeeDoctor",
// "RelatedSpecialty" ,
// "LaboratoryInvestigations",
// "RadiologyScans" ,
// "PlanOfCare" ,
// "RecommendedMedications",
// "RecommendedTherapy",
// "MedicalAndNursingRecommendations",
// "PrecautionsAndPrevention",
// "AssociatedMedicalConditions",
// "PregnancySpecialPrecautions",
// "ListOfReferences",
// "ChapterCode",
// "ParentCategory",
// "ChildCategory",
// "ParentCode",
// "ChildCode",
// "Diagnosis",
// "RiskIndex" ,
// "ChronicDisease",
// "Accident",
// "Poisoning",
// "Male",
// "Female",
// "FetusFirstTrimester",
// "FetusSecondTrimester",
// "FetusThirdTrimester",
// "FetusPostTerm",
// "Newborn",
// "m1_5y",
// "y5_15",
// "y15_25",
// "y25_45",
// "y45_65",
// "above65y",
// ]
