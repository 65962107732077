import React from 'react';
import { Button,Input ,Select  , Form, Alert, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {connect} from 'react-redux';
type Props = {
  onCancel: () => void;
  submitText?: string;
};
const {Option} = Select;

const children = [
  <Option value = "clinic" > clinic </Option>,
  <Option value = "medical center"> medical center </Option>,
  <Option value = "pharmacy"> pharmacy </Option>
]
const { Item } = Form;
const emptyUser = {
    type:null ,
    businessType:null,
    username: null,
    phoneNumber: null,
    email: null,
    password: null,
    quickLoginPassword: null
} 
const UsersForm = ({user  = emptyUser , onSubmit ,error , onCancel ,submitText }) => {
  
  const [form] = useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() =>  onSubmit(form.getFieldsValue()))
      .catch(() => null);
  };

  const handleCancel = () => {
    onCancel();
  };
console.log('form' , user)
  return (
    <>
      <Form  form={form} onFinish ={handleSubmit} initialValues = {user}>
  {error ?  <Alert  type = "error" message = {error.map((message) => { return   <p >{message} </p>  })} style = {{margin : '10px 0'}}/>  : ''} 
      <div className='form-group'>
        <Item  name='type' >
          <Input
            placeholder='type'
            type='text'

          />
        </Item>
        </div>
        <div className='form-group'>
          <Item name = "businessType">
        <Select
      style={{ width: '100%' }}
      placeholder="Please select bussiness type"
      
    >
      {children}
    </Select>
          </Item>
        </div>
      <div className='form-group'>
        <Item  name='username' >
          <Input
            placeholder='username'
            type='text'
         
          />
        </Item>
        </div>
        <div className='form-group'>
          <Item name='phoneNumber'>
          <Input
            placeholder='phone number'
            type= 'text'
           
          />
          </Item>
        </div>
        <div className='form-group'>
          <Item name='email'>
          <Input
            placeholder='email'
            type='email'
            
          />
          </Item>
        </div>
        
        <div className='form-group'>
          <Item name='password'>
          <Input
            placeholder='password'
            type= 'text'
            
          />
          </Item>
        </div>
        <div className='form-group'>
          <Item name='quickLoginPassword'>
          <Input
            placeholder='quick password'
            type= 'text'
  
          />
          </Item>
        </div>
    
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </Form>
    </>
  );
};
const mapStateToProps = state => {
  return {
    error : state.users.error
  }
}

export default connect(mapStateToProps)(UsersForm);
