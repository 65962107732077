import React, { useEffect, useState } from 'react';
import { Button, Input, InputNumber, Form, Collapse, Modal, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router';
import { editIcd } from '../../../redux/icd11/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IcdCart from './commonDcart/commonD';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import axios from 'axios';

import JoditEditor from 'jodit-react';
import FullPageLoading from '../../components/FullPageLoading';
import CommonForm from './form';
const { Panel } = Collapse;
const { Item } = Form;
const { TextArea } = Input;

const CommonDiagnosisDetail = () => {
  const pageData: IPageData = {
    title: `common diagnosis details`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'common diagnosis details'
      }
    ]
  };
  usePageData(pageData);
  const [loading, setloading] = useState(true);
  const params: { id: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    axios({
      method: 'GET',
      url: `u-consultation/commondiagnosis/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log('icd res', res.data);
      commonForm.setFieldsValue(res.data.data);
      setloading(false);
    });
  }, []);

  const [commonForm] = useForm();
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    console.log(values);
    axios({
      method: 'PUT',
      url: `u-consultation/commondiagnosis/${params.id}`,
      data: { ...values },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(() => {
      notification.info({
        message: `succeful updated`,
        placement: 'bottomRight'
      });
    });
    // icd11Form
    //   .validateFields()
    //   .then(() => dispatch(editIcd(values._id, icd11Form.getFieldsValue())))
    //   .catch(() => null);
  };

  const handleBack = () => {
    history.goBack();
  };

  const { Search } = Input;

  const handleSearch = (value) => {
    axios({
      method: 'GET',
      url: 'u-consultation/icd11',
      params: { ChildCode: value }
    }).then((res) => {
      commonForm.setFieldsValue(res.data.docs[0]);
    });
  };
  // const DeletIcd = () => {
  //   axios({
  //     method: 'DELETE',
  //     url: `u-consultation/icd11/${icd._id}`,
  //     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //   })
  //     .then(() => {
  //       notification.success({
  //         placement: 'bottomRight',
  //         description: 'deleted',
  //         message: 'this icd is deleted for ever'
  //       });
  //     })
  //     .then(() => {
  //       history.goBack();
  //     })
  //     .catch((err) => {
  //       notification.error({
  //         placement: 'bottomRight',
  //         description: 'some thing went wrong',
  //         message: 'please try again later'
  //       });
  //     });
  // };
  // const handleDeleteIcd = () => {
  //   Modal.warning({
  //     title: `do you need to delete this Icd ${icd.ChildCode}`,
  //     content: 'by click on delete you will loose this icd forever be carful please',
  //     okText: 'Delete',
  //     okType: 'danger',
  //     onOk: () => DeletIcd(),
  //     okCancel: true,
  //     cancelText: 'Cancel'
  //   });
  // };
  return (
    <>
      {loading ? (
        <>
          <FullPageLoading />
        </>
      ) : (
        <div>
          <Form form={commonForm} onFinish={handleSubmit} layout='vertical'>
            <Form.Item label='_id' name='_id' style={{ display: 'none' }}>
              <Input type='text' />
            </Form.Item>
            <div
              className='d-flex justify-content-between buttons-list settings-actions'
              style={{ width: '100%', marginBottom: '20px' }}
            >
              <Button onClick={handleBack} className='backButton'>
                go back
              </Button>

              <Button className='updateButton' onClick={() => commonForm.submit()}>
                update
              </Button>
              <Search
                className='icd-search'
                enterButton
                onSearch={handleSearch}
                style={{ width: '200px' }}
                placeholder='write child code'
              />
              {/* <Button className='deleteButton' danger >
                Delete Icd - 
              </Button> */}
            </div>
            <CommonForm />
          </Form>
        </div>
      )}
    </>
  );
};

export default CommonDiagnosisDetail;

// [
// "ICDVersion",
// "System",
// "ParentBillableCode",
// "ChildBillableCode",
// "DiagnosisVariances",
// "GeneralSymptoms",
// "SpecificSymptoms",
// "ClincalSigns",
// "LaboratoryDiagnosis",
// "RadiologyDiagnosis",
// "DiagnosticTest",
// "WhenToSeeDoctor",
// "RelatedSpecialty" ,
// "LaboratoryInvestigations",
// "RadiologyScans" ,
// "PlanOfCare" ,
// "RecommendedMedications",
// "RecommendedTherapy",
// "MedicalAndNursingRecommendations",
// "PrecautionsAndPrevention",
// "AssociatedMedicalConditions",
// "PregnancySpecialPrecautions",
// "ListOfReferences",
// "ChapterCode",
// "ParentCategory",
// "ChildCategory",
// "ParentCode",
// "ChildCode",
// "Diagnosis",
// "RiskIndex" ,
// "ChronicDisease",
// "Accident",
// "Poisoning",
// "Male",
// "Female",
// "FetusFirstTrimester",
// "FetusSecondTrimester",
// "FetusThirdTrimester",
// "FetusPostTerm",
// "Newborn",
// "m1_5y",
// "y5_15",
// "y15_25",
// "y25_45",
// "y45_65",
// "above65y",
// ]
