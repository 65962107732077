import React, { useEffect, useState } from 'react';

import {BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import * as action from './redux/auth/action';
import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';

import NotFound from './pages/sessions/404';
import customRout from './routing';
import ChangePassword from './pages/changePassword/changePassword';
import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';
import axios from 'axios';
import CustomRout from './routing/index';
const Routes = ({ routes, layout = '' }) => (
  <Switch>
    {routes.map((route, index) => (
      <Route
        key={index}
        exact={route.exact}
        path={layout.length > 0 ? `/${layout}/${route.path}` : `${route.path}\``}
        component={() => <route.component />}
      />
    ))}

    <Route path='*'>
      <Redirect to='/public/page-404' />
    </Route>
  </Switch>
);

// const DefaultRoutes = ({ layout }) => <Routes routes={defaultRoutes} layout={layout} />;

// const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

const App = (props) => {
  const [modal, setModal] = useState(props.open);
  useHideLoader();
  useEffect(() => {
    props.onTryAutoSignup();
    axios({
      method: 'GET',
      url: '/u-consultation/auth/me',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log('me', res);
    });
  }, []);
  return (
    <>
      <Modal visible={modal} onOk={() => setModal(false)} onCancel={() => setModal(false)}>
        <p>{props.message}</p>
      </Modal>
      <Router>
     <CustomRout />
      </Router>
  
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    forgetPassword: state.auth.forgetPass,
    open: state.general.open,
    message: state.general.message
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(action.authCheckState())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
