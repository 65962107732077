import { Form, Input, InputNumber, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import JoditEditor from 'jodit-react';
import React from 'react';

const FetchForm = ({ form }) => {
  return (
    <Form form={form} layout='vertical'>
      <Form.Item name='GeneralSymptoms' label='General Symptoms'>
        <JoditEditor value='' />
      </Form.Item>
      <Form.Item name='SpecificSymptoms' label='Specific Symptoms'>
        <JoditEditor value='' />
      </Form.Item>
      <Form.Item name='RiskIndex' label='Risk Index'>
        <InputNumber
          max={10}
          min={0}
          style={{ width: '100%', marginBottom: '10px' }}
          placeholder='RiskIndex'
          maxLength={1}
        />
      </Form.Item>
      <Form.Item name='Icd10Code' label='Icd10Code'>
        <Select mode='multiple' style={{ width: '100%' }} placeholder='Please select'></Select>
      </Form.Item>
      <Form.Item name='ListOfReferences' label='List Of References'>
        <JoditEditor value='' />
      </Form.Item>
    </Form>
  );
};

export default FetchForm;
