import React, { useState } from 'react';
import { Avatar, Table, Button, Modal, Tag } from 'antd';

import { IAdmin } from '../../../interfaces/admins';


import AdminForm from '../../../pages/dashboards/admins/adminForm';
import {show} from '../../../redux/users/action'
import {useDispatch} from 'react-redux'
import { useHistory } from 'react-router';

const AdminsTable = ({
  admins,
}) => {
  const [admin, setAdmin] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const dispatch = useDispatch()
  const closeModal = () => setVisibility(false);
  const history = useHistory()

  const handleShowInfo = () => history.push('/vertical/patient-profile');
  // const handleDeleteAdmin = (id) => onDeleteAdmin(id);
 
  const actions = (admin) => {
    const handleShow = () => {
      dispatch(show(admin))
      history.push('/vertical/adminDetail')
    }
    return(
    <div className='buttons-list nowrap'>
      <Button onClick={handleShow.bind({}, admin)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
    </div>
  );
  }

  const columns = [
   
    {
      key: 'name',
      dataIndex: 'name',
      title: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'email',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'id',
      dataIndex: '_id',
      title: 'ID',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {id}
        </span>
      )
    },
    {
      key: 'permissions',
      dataIndex: 'permissions',
      title: 'permissions',
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = admins.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={admins}
        columns={columns}
      />
    </>
  );
};

export default AdminsTable;
