import React from 'react';

import { Button,Input ,Select  , Form, Alert } from 'antd';
import { IAdmin } from '../../../interfaces/admins';
import { useForm } from 'antd/es/form/Form';
import {connect} from 'react-redux'

const {Option} = Select;
const defaultSubmitText = 'Add Admin';
const emptyAdmin = {
  fullname: null,
  email:null,
  permissions : [],
};
const children = [
  <Option value = "post" > post </Option>,
  <Option value = "edite"> edite </Option>
]

const { Item } = Form;
const AdminForm = ({
  submitText = defaultSubmitText,
  admin = emptyAdmin,
  message,
  onSubmit,
  onCancel
}) => {
  
  const [form] = useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() =>  onSubmit(form.getFieldsValue()))
      .catch(() => null);
      console.log(form.getFieldsValue())
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Form  form={form} onFinish ={handleSubmit}>
       {message ? <Alert type = "error" message ={message} style ={{margin : '20px 0'}}/> : '' }
      <div className='form-group'>
        <Item  name='name' >
          <Input
            placeholder='fullName'
            type='text'
            defaultValue ={admin.fullname}
          />
        </Item>
        </div>
        <div className='form-group'>
          <Item name='email'>
          <Input
            placeholder='email'
            type='email'
            defaultValue ={admin.email}
          />
          </Item>
        </div>
        <div className='form-group'>
          <Item name = "permissions">
        <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="Please select"
      defaultValue ={[...admin.permissions]}
    >
      {children}
    </Select>
          </Item>
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>

          <Button type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </Form>
    </>
  );
};
const mapStateToProps = (state) => {
  return{
    message : state.admins.message
  }
}
export default connect(mapStateToProps)(AdminForm);
