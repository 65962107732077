import React, { useEffect } from "react";
import VerticalLayout from "../layout/vertical/Vertical";

import DefaultRoutes from './default-routes'
import SessionRoutes from './session-routes'
 
function CustomRout() {
  // useEffect(() => {
  //   isAuthenticated();
  // });
  const isValidUser = localStorage.getItem("token");
  return (
    // <CompleteSignupRoutes />
    <div>{isValidUser ?<DefaultRoutes />: <SessionRoutes />}</div>
  );
}

export default CustomRout;