export const SET_ICD = 'SET_ICD';
export const FAIL_ADD = 'FAIL_ADD';
export const SUCCESSED_ADD = 'SUCCESSED_ADD';
export const FAIL_SET = 'FAIL_SET';
export const ADDING_ICD = 'ADDING_ICD';
export const CLOSE_MODALE = 'CLOSE_MODALE';
export const EDITING_USER = 'EDITING_ICD';
export const SHOW_ICD = 'SHOW_ICD';
export const UPDATED_ICD = 'UPDATED_ICD';
export const NEXT_ICD = 'NEXT_ICD';
export const PERVIOS_ICD = 'NEXT_ICD';
export const MORE_PAGES = 'MORE_PAGES'