import React, { Component } from 'react';
import InnerAppLayout from '../../../layout/inner-app-layout';
import MailMenu from './MailMenu';

import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import MailContent from './MailContent';

const Mail = (props) => {
  const pageData: IPageData = {
    title: `contact us`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'contact us'
      }
    ]
  };
  usePageData(pageData);
  return (
    <div className='mail'>
      <InnerAppLayout
        sideContent={<MailMenu {...props} />}
        mainContent={<MailContent {...props} />}
        border
      />
    </div>
  );
};

export default Mail;
