import * as actionType from './types';
import axios from 'axios';
import { message, Modal } from 'antd';

export const setIcds = (icds, total, page) => {
  return {
    type: actionType.SET_ICD11,
    icds: icds,
    total: total,
    page
  };
};
export const failSetUsers = () => {
  return {
    type: actionType.FAIL_SET_ICD11
  };
};
export const failAdd = (error) => {
  return {
    type: actionType.FAIL_ADD_ICD11,
    error: error
  };
};
export const successedAdd = () => {
  return {
    type: actionType.SUCCESSED_ADD_ICD11
  };
};
export const addingIcd = () => {
  return {
    type: actionType.ADDING_ICD11
  };
};
export const closeModal = () => {
  return {
    type: actionType.CLOSE_MODALE
  };
};
export const edit = () => {
  return {
    type: actionType.CLOSE_MODALE
  };
};
export const show = (icd) => {
  return {
    type: actionType.SHOW_ICD_ICD11,
    icd: icd
  };
};
export const nextIcd = (icd, page) => {
  return {
    type: actionType.NEXT_ICD11,
    page,
    icd
  };
};

export const getmorepages = (icds, page) => {
  return {
    type: actionType.MORE_PAGES_ICD11,
    page,
    icds
  };
};
export const getIcd = (page) => {
  return (dispatch) => {
    axios({
      method: 'GET',
      url: '/u-consultation/icd11',
      params: { page: page, limit: 1 },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    })
      .then((resp) => {
        dispatch(nextIcd(resp.data.docs[0], resp.data.page));
        localStorage.setItem('icdPage', resp.data.page);
      })
      .catch((err) => {
        dispatch(failSetUsers());
      });
  };
};
export const getIcds = (page) => {
  return (dispatch) => {
    axios({
      method: 'GET',
      url: '/u-consultation/icd11',
      params: { page: page, limit: 10 },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    })
      .then((resp) => {
        dispatch(setIcds(resp.data.docs, resp.data.totalDocs, resp.data.page));
        localStorage.setItem('icdPage', resp.data.page);
      })
      .catch((err) => {
        dispatch(failSetUsers());
      });
  };
};
function successAdded() {
  let secondsToGo = 500;
  const modal = Modal.success({
    title: 'successfuly added Icd',
    content: `this Icd is successfully added`,
    okText: 'got it',
    okButtonProps: { style: { backgroundColor: '#520974', border: 'none' } },
    className: 'umedmi-model'
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 1000);
}
export const addIcd = (data) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: '/u-consultation/icd11',
      data: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    })
      .then((resp) => {
        dispatch(successedAdd());
        successAdded();
      })
      .catch((err) => {
        dispatch(failAdd(err.response.data.message));
        console.log(err.response.data.message);
      });
  };
};

function successupdated() {
  message.success('successfuly update Icd');
}

export const editIcd = (id, data) => {
  return (dispatch) => {
    axios({
      method: 'PUT',
      url: `/u-consultation/icd11/${id}`,
      data: {
        ...data
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    }).then((res) => {
      successupdated();
      dispatch(getIcds(localStorage.getItem('icdPage')));
    });
  };
};

export const getMoreIcds = (page) => {
  return (dispatch) => {
    axios({
      method: 'GET',
      url: '/u-consultation/icd11',
      params: { page: page, limit: 10 },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    }).then((resp) => {
      dispatch(getmorepages(resp.data.docs, resp.data.page));
    });
  };
};
