import {SUCCESS_ADD_ADMIN} from '../admins/types';
import {SUCCESSED_ADD , UPDATED_USER} from '../users/types';
import {} from '../users/types'

const initState = {
    message : null,
    open : false
}

export const reducer = (state= initState , action) => {
    switch (action.type){
        case SUCCESSED_ADD :
                return{
                    ...state,
                    open  : true,
                    message: 'you successfully added new user',
                }
        default :
        return state
    } 
}
