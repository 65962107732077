import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Form,
  Collapse,
  Modal,
  notification,
  Row,
  Select,
  Radio,
  Slider,
  Col
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router';
import { editIcd } from '../../../redux/icd/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import FullPageLoading from '../../components/FullPageLoading';
import { Parser } from 'expr-eval';
import ChoiceForm from './choiceForm';

import Axios from 'axios';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Specialtyinput from '../../components/specialtyInput/specialtyinput';
import MathFormulaModal from './mathFormula';
const { Panel } = Collapse;
const Option = Select;
const emptyformula = {
  _id: null,
  formula: null,
  formulaKeys: [
    {
      name: null,
      key: null,
      type: null,
      maxValue: '',
      minValue: '',
      choices: [
        {
          title: null,
          name: null,
          value: ''
        }
      ]
    }
  ],
  title: null,
  description: null,
  resultDescription: null,
  resultMeasuringUnit: null,
  formulaDetails: null,
  speciality: null,
  hashtags: null
};
const FormulaForm = () => {
  const pageData: IPageData = {
    title: `formula`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'formula'
      }
    ]
  };
  const [formula, setFormula] = useState(emptyformula);
  const [loading, setloading] = useState(true);
  const params: { id: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    // FormulaForm.isFieldsTouched() && setTested(false);
    axios({
      method: 'GET',
      url: `formula/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log('formula', res.data);
      setFormula({ ...res.data });
      FormulaForm.setFieldsValue(res.data);
      setloading(false);
    });
  }, []);

  usePageData(pageData);
  const [FormulaForm] = useForm();
  const [testForm] = useForm();
  const [tested, setTested] = useState(true);
  const [showTest, setShowTest] = useState(false);
  const handleSubmit = () => {
    !tested && setShowTest(true);
    tested &&
      Axios({
        method: 'PATCH',
        url: `formula/update/${formula._id}`,
        data: FormulaForm.getFieldsValue()
      }).then(() => {
        return notification.success({
          placement: 'topRight',
          message: 'formula updated successfully'
        });
      });
  };

  const handleTestSubmit = () => {
    const { expectedValue, ...values } = testForm.getFieldsValue();
    const parsedFormula = Parser.parse(FormulaForm.getFieldValue('formula'));
    const value = parsedFormula.evaluate(values);
    if (value == expectedValue) {
      setTested(true);
      setShowTest(false);
      notification.success({
        placement: 'topRight',
        message: 'formula is work successfully you can submit formula now'
      });
    } else {
      notification.warning({
        placement: 'bottomRight',
        message: `no match between result ${value} and expected value ${expectedValue}`
      });
    }
  };
  const handleBack = () => {
    history.goBack();
  };

  const Deleteformula = () => {
    axios({
      method: 'DELETE',
      url: `formula/${formula._id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
      .then(() => {
        notification.success({
          placement: 'bottomRight',
          description: 'deleted',
          message: 'this icd is deleted for ever'
        });
      })
      .then(() => {
        history.goBack();
      })
      .catch((err) => {
        notification.error({
          placement: 'bottomRight',
          description: 'some thing went wrong',
          message: 'please try again later'
        });
      });
  };
  const handleDelete = () => {
    Modal.warning({
      title: `do you need to delete this formula ${formula.title}`,
      content: 'by click on delete you will loose this icd forever be carful please',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => Deleteformula(),
      okCancel: true,
      cancelText: 'Cancel'
    });
  };
  const [sliderValue, setsliderValue] = useState();
  const handleSlideronChange = (value) => {
    setsliderValue(value);
  };
  return (
    <>
      {loading ? (
        <>
          <FullPageLoading />
        </>
      ) : (
        <>
          <MathFormulaModal />
          <Form
            form={FormulaForm}
            onFinish={handleSubmit}
            initialValues={formula}
            layout='vertical'
            onTouchStart={() => setTested(false)}
          >
            <Collapse defaultActiveKey={['formula', 'title', 'formulaKeys']}>
              <Panel header='title' key='title'>
                <Form.Item
                  name='title'
                  rules={[{ required: true, message: 'Please input the title!' }]}
                >
                  <Input />
                </Form.Item>
              </Panel>
              <Panel header='formula' key='formula'>
                <Form.Item
                  name='formula'
                  rules={[{ required: true, message: 'Please input the formula!' }]}
                >
                  <Input onChange={() => setTested(false)} />
                </Form.Item>
              </Panel>

              <Panel header='formulaKeys' key='formulaKeys'>
                <Form.List name='formulaKeys'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Row key={field.key} justify='space-between'>
                          <Form.Item
                            {...field}
                            label='name'
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            rules={[{ message: 'Missing maxValue' }]}
                          >
                            <Input onChange={() => setTested(false)} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label='key'
                            name={[field.name, 'key']}
                            fieldKey={[field.fieldKey, 'key']}
                            rules={[{ message: 'Missing maxValue' }]}
                          >
                            <Input onChange={() => setTested(false)} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label='type'
                            name={[field.name, 'type']}
                            fieldKey={[field.fieldKey, 'type']}
                            rules={[{ required: true, message: 'Missing type' }]}
                          >
                            <Select style={{ width: 130 }}>
                              <Option value='range'>range</Option>
                              <Option value='choices'>choices</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label='minValue'
                            name={[field.name, 'minValue']}
                            fieldKey={[field.fieldKey, 'minValue']}
                            rules={[{ message: 'Missing minValue' }]}
                          >
                            <Input type='number' />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label='maxValue'
                            name={[field.name, 'maxValue']}
                            fieldKey={[field.fieldKey, 'maxValue']}
                            rules={[{ message: 'Missing maxValue' }]}
                          >
                            <Input type='number' />
                          </Form.Item>

                          <Form.Item>
                            <ChoiceForm fieldKey={field.name} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Row>
                      ))}

                      <Form.Item>
                        <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                          Add key
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Panel>
              <Panel header='description' key='description'>
                <Form.Item name='description'>
                  <JoditEditor value='' />
                </Form.Item>
              </Panel>
              <Panel header='resultDescription' key='resultDescription'>
                <Form.Item name='resultDescription'>
                  <Input />
                </Form.Item>
              </Panel>

              <Panel header='resultMeasuringUnit' key='resultMeasuringUnit'>
                <Form.Item name='resultMeasuringUnit'>
                  <Input />
                </Form.Item>
              </Panel>

              <Panel header='formulaDetails' key='formulaDetails'>
                <Form.Item name='formulaDetails'>
                  <Input />
                </Form.Item>
              </Panel>

              <Panel header='speciality' key='speciality'>
                <Specialtyinput />
              </Panel>
            </Collapse>
            <div
              className='d-flex justify-content-between buttons-list settings-actions'
              style={{ width: '100%' }}
            >
              <Button type='link' onClick={() => history.goBack()}>
                go back
              </Button>
              <Button danger onClick={handleDelete}>
                Delete
              </Button>
              <Button
                type={tested ? 'primary' : 'default'}
                style={{ backgroundColor: tested ? 'green' : null }}
                htmlType='submit'
              >
                {tested ? 'update Formula' : 'Test'}
              </Button>
            </div>
          </Form>
          <Modal
            visible={showTest}
            onOk={() => testForm.submit()}
            okText='test'
            onCancel={() => setShowTest(false)}
          >
            {showTest ? (
              <Form form={testForm} onFinish={handleTestSubmit}>
                {formula.formulaKeys.map((item) => {
                  return (
                    <Form.Item name={item.key} label={item.name}>
                      {item.type === 'range' ? (
                        <Slider
                          min={parseInt(item.minValue)}
                          max={parseInt(item.maxValue)}
                          onChange={handleSlideronChange}
                          tooltipVisible
                          value={typeof sliderValue === 'number' ? sliderValue : 0}
                        />
                      ) : (
                        <Radio.Group>
                          {item.choices.map((item) => {
                            return <Radio value={item.value}>{item.name}</Radio>;
                          })}
                        </Radio.Group>
                      )}
                    </Form.Item>
                  );
                })}

                <Form.Item
                  name='expectedValue'
                  label='expectedValue'
                  rules={[{ required: true, message: 'Please input expected value!' }]}
                >
                  <Input type='number' />
                </Form.Item>
              </Form>
            ) : null}
          </Modal>
        </>
      )}
    </>
  );
};

export default FormulaForm;
