import {SET_USERS , FAIL_ADD ,FAIL_SET, SUCCESSED_ADD, ADDING_USER ,CLOSE_MODALE , SHOW_USER , EDITING_USER} from './types'

const initState = {
    users : [],
    curruntUser : [],
    addingUser : false,
    loading: true,
    error : null,
    editing : false,
    total : null,
    role : 'clinic'
}

export const userReducer = (state = initState , action) => {
    switch (action.type) {
        case SET_USERS:
            return {...state ,
                users : action.users,
                loading : false,
                total : action.total
            }
        case FAIL_SET :
            return {
                ...state,
                loading: false,
                error : 'please check your connection and try again'
            }
        case ADDING_USER:
            return{
                ...state,
                addingUser :true
            }
        case CLOSE_MODALE:
            return{
                ...state,
                addingUser :false,
                error  : null
            }
        case SUCCESSED_ADD :
            return{
                ...state,
                addingUser :false,
                error: null
            }
        case FAIL_ADD:
            return {
                ...state,
                error: action.error
            }
        case SHOW_USER :
            return {
                ...state,
                user: action.user
            }
        case SHOW_USER :
            return {
                ...state,
                editing: true
            }
        default:
           return state
    }
}