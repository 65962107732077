import * as actionType from './types';

const initState = {
  icds: [],
  curruntIcd: [],
  addingIcd: false,
  loading: true,
  error: null,
  editing: false,
  total: null,
  role: 'clinic',
  page: 1
};

export const icd11Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_ICD11:
      return {
        ...state,
        icds: action.icds,
        loading: false,
        total: action.total,
        page: action.page
      };
    case actionType.FAIL_SET_ICD11:
      return {
        ...state,
        loading: false,
        error: 'please check your connection and try again'
      };
    case actionType.ADDING_ICD11:
      return {
        ...state,
        addingUser: true
      };
    case actionType.CLOSE_MODALE:
      return {
        ...state,
        addingUser: false,
        error: null
      };
    case actionType.SUCCESSED_ADD_ICD11:
      return {
        ...state,
        addingUser: false,
        error: null
      };
    case actionType.FAIL_ADD_ICD11:
      return {
        ...state,
        error: action.error
      };
    case actionType.SHOW_ICD_ICD11:
      return {
        ...state,
        icd: action.icd,
        editing: true
      };
    default:
      return state;
  }
};
