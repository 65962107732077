import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Row, Select } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const CommonForm = () => {
  const history = useHistory();

  const handleCancel = () => {
    history.goBack();
  };
  function onSearchSelect(val) {
    console.log(val);
    const text = document.querySelectorAll('p.specialtyName');
    for (let i = 0; i < text.length; i++) {
      const originalText = text[i].textContent;
      let word = val.trim();
      word = word.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      const regexp = new RegExp(word, 'gi');
      text[i].innerHTML = originalText.replace(regexp, `<span class = "bold">${word}</span>`);
      console.log(text[i]);
    }
  }
  const [specialty, setSepcialty] = useState([]);
  useEffect(() => {
    Axios({
      method: 'GET',
      url: 'u-consultation/speciality',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      setSepcialty(response.data.data);
    });
  }, []);

  const SpecialtyOption = specialty.map((item) => {
    return (
      <Option value={item._id} key={item.mainSpeciality.en}>
        <div>
          <p className='specialtyName'>{item.mainSpeciality.en} </p>
        </div>
      </Option>
    );
  });
  return (
    <>
      <Form.Item name='CodeVersion' label='CodeVersion'>
        <Input placeholder='CodeVersion' />
      </Form.Item>
      <Form.Item name='ICDCode' label='ICDCode'>
        <Input placeholder='ICDCode' />
      </Form.Item>
      <Form.Item name='Diagnosis' label='Diagnosis'>
        <Input placeholder='Diagnosis' />
      </Form.Item>
      <Form.List name='Specialities'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} justify='space-between'>
                <Form.Item
                  {...field}
                  label='speciality'
                  name={[field.name, 'speciality']}
                  fieldKey={[field.fieldKey, 'speciality']}
                >
                  <Select
                    className='specialty'
                    style={{ width: '300px' }}
                    dropdownClassName='specialtyDropdwonRegister'
                    onSearch={onSearchSelect}
                    optionFilterProp='children'
                    showSearch
                    placeholder='please select your main'
                    filterOption={(input, option) =>
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {SpecialtyOption}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label='countryCode'
                  name={[field.name, 'countryCode']}
                  fieldKey={[field.fieldKey, 'countryCode']}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label='count'
                  name={[field.name, 'count']}
                  fieldKey={[field.fieldKey, 'count']}
                >
                  <InputNumber />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                Add speciality
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.List name='Countries'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} justify='space-between'>
                <Form.Item
                  {...field}
                  label='countryCode'
                  name={[field.name, 'countryCode']}
                  fieldKey={[field.fieldKey, 'countryCode']}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label='count'
                  name={[field.name, 'count']}
                  fieldKey={[field.fieldKey, 'count']}
                >
                  <InputNumber />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                Add country
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.List name='VisitType'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} justify='space-between'>
                <Form.Item
                  {...field}
                  label='new'
                  name={[field.name, 'new']}
                  fieldKey={[field.fieldKey, 'new']}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  {...field}
                  label='followup'
                  name={[field.name, 'followup']}
                  fieldKey={[field.fieldKey, 'followup']}
                >
                  <InputNumber />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                Add VisitType
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.List name='Gender'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} justify='space-between'>
                <Form.Item
                  {...field}
                  label='male'
                  name={[field.name, 'male']}
                  fieldKey={[field.fieldKey, 'male']}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  {...field}
                  label='female'
                  name={[field.name, 'female']}
                  fieldKey={[field.fieldKey, 'female']}
                >
                  <InputNumber />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                Add Gender
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <div
        className='d-flex justify-content-between buttons-list settings-actions'
        style={{ width: '100%' }}
      >
        <Button type='link' onClick={handleCancel}>
          go back
        </Button>

        <Button type='primary' htmlType='submit'>
          add common diagnosis
        </Button>
      </div>
    </>
  );
};

export default CommonForm;
