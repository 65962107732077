import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import MailCompose from './MailCompose';
import MaiDetail from './MaiDetail';
import MailItem from './MailItem';
const MailContent = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/compose`} component={MailCompose} />
      <Route path={`${match.url}/:category/:id`} component={MaiDetail} />
      <Route exact path={`${match.url}/:category`} component={MailItem} />
    </Switch>
  );
};

export default MailContent;
