// import { IRoute } from '../interfaces/routing';
import React from 'react';

import SettingsPage from '../pages/settings/Settings';

import PatientsPage from '../pages/dashboards/patients/Patients';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
// import AppointmentsPage from '../pages/dashboards/appointments/AppointmentsPage';
// import DoctorsPage from '../pages/dashboards/doctors/Doctors';
// import AlertsPage from '../pages/components/AlertsPage';
// import AutocompletePage from '../pages/components/AutocompletePage';
// import BadgesPage from '../pages/components/BadgesPage';
// import ContactsPage from '../pages/components/ContactsPage';
// import InputsPage from '../pages/components/InputsPage';
// import RatingPage from '../pages/components/RatingsPage';
// import SelectsPage from '../pages/components/SelectsPage';
// import SwitchersPage from '../pages/components/SwitchersPage';
// import CheckboxesPage from '../pages/components/CheckboxesPage';
// import RadiosPage from '../pages/components/RadiosPage';
// import TimelinePage from '../pages/components/TimlinesPage';
// import CardsPage from '../pages/components/CardsPage';
// import ButtonsPage from '../pages/components/ButtonsPage';
// import TextareasPage from '../pages/components/TextAreaPage';
// import DoctorProfilePage from '../pages/medic/DoctorsProfilePage';
// import PatientProfilePage from '../pages/medic/PatientProfilePage';
// import Payments from '../pages/dashboards/payments/Payments';
// import Departments from '../pages/medic/Departments';
// import InvoicesPage from '../pages/services/InvoicesPage';
// import InvoicePage from '../pages/services/InvoicePage';
// import PricingPage from '../pages/services/PricingPage';
// import EventsTimelinePage from '../pages/services/events-timeline/EventsTimeline';
// import UserProfilePage from '../pages/services/UserProfilePage';
// import EditAccountPage from '../pages/services/EditAccounPage';
// import EventsCalendarPage from '../pages/services/EventsCalendarPage';
// import EchartsPage from '../pages/charts/echarts/EchartsPage';
// import TypographyPage from '../pages/typography/TypographyPage';
// import RechartsPage from '../pages/charts/recharts/Rechart';
// import ChartJsPage from '../pages/charts/chart-js/ChartJs';
// import GoogleMapsPage from '../pages/maps/GoogleMapPage';
// import PageVectorMaps from '../pages/maps/VectorMapsPage';
// import WorldMapPage from '../pages/maps/WorldMapPage';
// import TablesPage from '../pages/tables/TablesPage';
// import FormElementsPage from '../pages/forms/FormElementsPage';
// import FormLayoutsPage from '../pages/forms/FormLayoutsPage';
// import FormValidationPage from '../pages/forms/FormValidationPage';
// import AntdIconsPage from '../pages/icons/AntdIconsPage';
// import IconsOptionsPage from '../pages/icons/IconsOptionsPage';
// import IcofontIconsPage from '../pages/icons/IcofontIconsPage';
import adminsPage from '../pages/dashboards/admins/admins';
import usersPage from '../pages/dashboards/users/users';
import udeUsersPage from '../pages/dashboards/udeUser/udeUsers';
import UdeUsersForm from '../pages/dashboards/udeUser/udeUser';
import user from '../pages/dashboards/users/user';
// import Profiles from '../pages/dashboards/profiles/profiles';
// import profileDetails from '../pages/dashboards/profiles/profileDetails';
// import Subscription from '../pages/dashboards/subscription/subscription';
// import SubscriptionPlane from '../pages/dashboards/subscription/subscriptionplan';
// import subscriptionReq from '../pages/dashboards/subscriptionRequest/subscriptionReq';
// import subscriptionDetail from '../pages/dashboards/subscriptionRequest/details';
// import adminDetail from '../pages/dashboards/admins/adminsDetails';
import IcdTenPage from '../pages/dashboards/icd10/Icd10Page';
import IcdTen from '../pages/dashboards/icd10/icd';
import AddIcdTen from '../pages/dashboards/icd10/addForm';
import FormulaForm from '../pages/dashboards/formula/formula';
// import changePassword from '../pages/changePassword/changePassword';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
// import patients from '../pages/chart-options/patients';
import VerticalLayout from '../layout/vertical/Vertical';
import FormulasPage from '../pages/dashboards/formula/formals';
import AddingFormulaForm from '../pages/dashboards/formula/addForm';
import FormulaPrview from '../pages/dashboards/formula/formulaPreview';
import Icd11Page from '../pages/dashboards/icd11/icd11Page';
import Icd11Detail from '../pages/dashboards/icd11/icd11';
import AddIcd11Form from '../pages/dashboards/icd11/icd11addForm';
import CommonDiagnosisPage from '../pages/dashboards/commonDiagnosis/commonDPage';
import CommonDiagnosisDetail from '../pages/dashboards/commonDiagnosis/commonD';
import AddCommonDiagnosis from '../pages/dashboards/commonDiagnosis/commonaddForm';
import Mail from '../pages/dashboards/mail/index';
import biosystemPage from '../pages/dashboards/biosystem/biosystemPage';
import AddBiosystemForm from '../pages/dashboards/biosystem/biosystemaddForm';
import IcdCmDetail from '../pages/dashboards/IcdCm/icdCm';
import icdCmPage from '../pages/dashboards/IcdCm/icdCmPage';
import AddIcdCmForm from '../pages/dashboards/IcdCm/icdCmaddForm';
function defaultRoutes() {
  return (
    <>
      <Router>
        <Switch>
          <VerticalLayout>
            <Route exact path='/' component={IcdTenPage} />
            <Route path='/settings' component={SettingsPage} />
            <Route path='/patients' component={PatientsPage} />
            <Route path='/admins' component={adminsPage} />
            <Route path='/users' component={usersPage} />
            <Route path='/user/:id' component={user} />
            <Route path='/ude-users' component={udeUsersPage} />
            <Route path='/udeUserDetails/:id' component={UdeUsersForm} />
            <Route path='/ICD10' component={IcdTenPage} />
            <Route path='/IcdDetail/:id' component={IcdTen} />
            <Route path='/AddingIcd' component={AddIcdTen} />
            <Route path='/ICD11' component={Icd11Page} />
            <Route path='/Icd11Detail/:id' component={Icd11Detail} />
            <Route path='/AddingIcd11' component={AddIcd11Form} />
            <Route path='/ICDCm' component={icdCmPage} />
            <Route path='/IcdCmDetail/:id' component={IcdCmDetail} />
            <Route path='/AddingIcdCm' component={AddIcdCmForm} />
            <Route path='/commonDiagnosis' component={CommonDiagnosisPage} />
            <Route path='/CommonDetail/:id' component={CommonDiagnosisDetail} />
            <Route path='/addCommonDiagnosis' component={AddCommonDiagnosis} />
            <Route path='/formulas' component={FormulasPage} />
            <Route path='/AddingFormula' component={AddingFormulaForm} />
            <Route path='/PreviwFormula/:id' component={FormulaPrview} />
            <Route path='/contact-request' component={Mail} />
            <Route path='/biosystem' component={biosystemPage} />
            <Route path='/AddingBiosystem' component={AddBiosystemForm} />
            <Route path='/editingBiosystem/:editing/:id' component={AddBiosystemForm} />
            <Route path='/formulaDetail/:id' component={FormulaForm} />
            <Route path='/dashboard' component={DashboardPage} />
            {/* <Redirect to='/ICD10' /> */}
          </VerticalLayout>
          {/* <Route path="/ICD11" component={CompletePage} /> */}
        </Switch>
      </Router>
    </>
  );
}
export default defaultRoutes;

// [
//   {
//     path: 'settings',
//     component: SettingsPage
//   },
//   {
//     path: 'patients',
//     component: PatientsPage
//   },
//   {
//     path: 'admins',
//     component: adminsPage
//   },
//   {
//     path: 'users',
//     component: usersPage
//   },
//   {
//     path: 'ICD10',
//     component: IcdTenPage
//   },
//   {
//     path: 'IcdDetail/:id',
//     component: IcdTen
//   },
//   {
//     path: 'AddingIcd',
//     component: AddIcdTen
//   },
//   {
//     path: 'ICD11',
//     component: IcdElevenPage
//   },
//   {
//     path: 'Icd11Detail',
//     component: IcdEleven
//   },
//   {
//     path: 'AddingIcd11',
//     component: AddIcdEleven
//   },
//   {
//     path: 'user',
//     component: user
//   },
//   {
//     path: 'profiles',
//     component: Profiles
//   },
//   {
//     path: 'profileDetails',
//     component: profileDetails
//   },
//   {
//     path: 'subscription',
//     component: Subscription
//   },
//   {
//     path: 'subscriptionPlan',
//     component: SubscriptionPlane
//   },
//   {
//     path: 'subscriptionDetail',
//     component: subscriptionDetail
//   },
//   {
//     path: 'adminDetail',
//     component: adminDetail
//   },
//   {
//     path: 'subscriptionReq',
//     component: subscriptionReq
//   },
//   {
//     path: 'default-dashboard',
//     component: DashboardPage
//   },
//   {
//     path: 'appointments',
//     component: AppointmentsPage
//   },
//   {
//     path: 'doctors',
//     component: DoctorsPage
//   },
//   {
//     path: 'doctor-profile',
//     component: DoctorProfilePage
//   },
//   {
//     path: 'alerts',
//     component: AlertsPage
//   },
//   {
//     path: 'autocompletes',
//     component: AutocompletePage
//   },
//   {
//     path: 'badges',
//     component: BadgesPage
//   },
//   {
//     path: 'contacts',
//     component: ContactsPage
//   },
//   {
//     path: 'inputs',
//     component: InputsPage
//   },
//   {
//     path: 'ratings',
//     component: RatingPage
//   },
//   {
//     path: 'modal-windows',
//     component: ModalsPage
//   },
//   {
//     path: 'selects',
//     component: SelectsPage
//   },
//   {
//     path: 'switchers',
//     component: SwitchersPage
//   },
//   {
//     path: 'checkboxes',
//     component: CheckboxesPage
//   },
//   {
//     path: 'radio-buttons',
//     component: RadiosPage
//   },
//   {
//     path: 'v-timeline',
//     component: TimelinePage
//   },
//   {
//     path: 'cards',
//     component: CardsPage
//   },
//   {
//     path: 'buttons',
//     component: ButtonsPage
//   },
//   {
//     path: 'textareas',
//     component: TextareasPage
//   },
//   {
//     path: 'patient-profile',
//     component: PatientProfilePage
//   },
//   {
//     path: 'payments',
//     component: Payments
//   },
//   {
//     path: 'departments',
//     component: Departments
//   },
//   {
//     path: 'invoices',
//     component: InvoicesPage
//   },
//   {
//     path: 'invoice',
//     component: InvoicePage
//   },
//   {
//     path: 'pricing',
//     component: PricingPage
//   },
//   {
//     path: 'events-timeline',
//     component: EventsTimelinePage
//   },
//   {
//     path: 'user-profile',
//     component: UserProfilePage
//   },
//   {
//     path: 'edit-account',
//     component: EditAccountPage
//   },
//   {
//     path: 'events-calendar',
//     component: EventsCalendarPage
//   },
//   {
//     path: 'echarts',
//     component: EchartsPage
//   },
//   {
//     path: 'recharts',
//     component: RechartsPage
//   },
//   {
//     path: 'chart-js',
//     component: ChartJsPage
//   },
//   {
//     path: 'typography',
//     component: TypographyPage
//   },
//   {
//     path: 'google-map',
//     component: GoogleMapsPage
//   },
//   {
//     path: 'vector-map',
//     component: PageVectorMaps
//   },
//   {
//     path: 'world-map',
//     component: WorldMapPage
//   },
//   {
//     path: 'tables',
//     component: TablesPage
//   },
//   {
//     path: 'form-elements',
//     component: FormElementsPage
//   },
//   {
//     path: 'form-layouts',
//     component: FormLayoutsPage
//   },
//   {
//     path: 'form-validation',
//     component: FormValidationPage
//   },
//   {
//     path: 'ant-icons',
//     component: AntdIconsPage
//   },
//   {
//     path: 'icons-options',
//     component: IconsOptionsPage
//   },
//   {
//     path: 'icofont-icons',
//     component: IcofontIconsPage
//   }
// ];
