import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Modal,
  Radio,
  Row,
  Spin,
  notification
} from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useForm } from 'antd/lib/form/Form';
import Axios from 'axios';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { upsert } from '../../../utils';
import Icdsearch from '../../dashboards/IcdCm/Icdsearch';
import FetchForm from './FetchForm';
import './modal.scss';
const FetchfromIcdModal = (props) => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const qeury = useQuery();

  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [icdData, setIcdData] = useState([]);
  const [currentCodes, setCurrentCodes] = useState([]);
  const [form] = useForm();
  const history = useHistory();

  useEffect(() => {
    setCurrentCodes(props.currentCodes);
    qeury.get('modal') === 'open' && setVisible(true)
  }, [props.currentCodes, qeury.get('modal')]);
  useLayoutEffect(() => {
    setIcdData([]);
    setloading(true);
    Axios({
      method: 'GET',
      url: 'u-consultation/icd/search',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      params: { diagnosis: props.diagnosis, page: 1, limit: 10 }
    }).then( (response) => {
      setloading(false);
      setIcdData(response.data)
     props.currentCodes.forEach(item =>{
      const itemExist = response.data.find(icd => icd.ChildCode === item)
      console.log({icdData,itemExist , item})
       if(!itemExist) {getIcd10Code(item).then(res => {
          setIcdData(oldArray => [res[0] , ...oldArray]);
        })}
      })
      
      ;
    });
  }, [qeury.get('modal')]);
  const getIcd10Code =async  (code)=>{
    let result = [];
   await Axios({
       method: 'GET',
       url: 'u-consultation/icd',
       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
       params: { ChildCode: code }
     }).then((res) => {
        result.push(res.data.docs[0]);
     });
     return result
   }
  useEffect(() => {
    form.resetFields();
    props.currentCodes.forEach((item) => {
      let icd = icdData.find((icd) => icd.ChildCode === item);
      form.setFieldsValue({
        GeneralSymptoms: form.getFieldValue('GeneralSymptoms')?.trim()
          ? form.getFieldValue('GeneralSymptoms') +
            '<br>=================================<br>' +
            icd?.GeneralSymptoms
          : icd?.GeneralSymptoms,
        SpecificSymptoms: form.getFieldValue('SpecificSymptoms')
          ? form.getFieldValue('SpecificSymptoms') +
            '<br>=================================<br>' +
            icd?.SpecificSymptoms
          : icd?.SpecificSymptoms,
        RiskIndex: icd?.RiskIndex,
        ListOfReferences: form.getFieldValue('ListOfReferences')
          ? form.getFieldValue('ListOfReferences') +
            '<br>=================================<br>' +
            icd?.ListOfReferences
          : icd?.ListOfReferences,
        Icd10Code: currentCodes
      });
    });
  }, [icdData, props.currentCodes]);
  const codes = icdData.map((item) => (
    <Checkbox value={item.ChildCode} key={item.ChildCode}>
      {item.ChildCode + ' --' + item.Diagnosis}
    </Checkbox>
  ));
  const handleClose = () => {
    setVisible(false);
    history.push(history.location.pathname);
  };
  const handleChange = (checkedValues: CheckboxValueType[]) => {
    var b = new Set(checkedValues);
    var difference = [...currentCodes].filter((x) => !b.has(x));
    if (difference.length > 0) {
      const icd = icdData.find((icd) => icd.ChildCode === difference[0]);
      form.setFieldsValue({
        GeneralSymptoms: form.getFieldValue('GeneralSymptoms')?.replace(icd.GeneralSymptoms, ''),
        SpecificSymptoms: form
          .getFieldValue('SpecificSymptoms')
          ?.replace(icd?.SpecificSymptoms, ''),
        ListOfReferences: form
          .getFieldValue('ListOfReferences')
          ?.replace(icd?.ListOfReferences, ''),
        Icd10Code: checkedValues
      });
    } else {
      const icd = icdData.find((icd) => icd.ChildCode === checkedValues[checkedValues.length - 1]);
      form.setFieldsValue({
        GeneralSymptoms: form.getFieldValue('GeneralSymptoms')
          ? form.getFieldValue('GeneralSymptoms') +
            '=================================<br>' +
            icd?.GeneralSymptoms
          : icd?.GeneralSymptoms,
        SpecificSymptoms: form.getFieldValue('SpecificSymptoms')
          ? form.getFieldValue('SpecificSymptoms') +
            '=================================<br>' +
            icd?.SpecificSymptoms
          : icd?.SpecificSymptoms,
        RiskIndex: icd?.RiskIndex,
        ListOfReferences: form.getFieldValue('ListOfReferences')
          ? form.getFieldValue('ListOfReferences') +
            '=================================<br>' +
            icd?.ListOfReferences
          : icd?.ListOfReferences,
        Icd10Code: checkedValues
      });
    }
    setCurrentCodes(checkedValues);
    if (checkedValues.length === 0) {
      form.resetFields();
    }
  };

  
  const handleSearchFullText = () => {
    setloading(true);
    Axios({
      method: 'GET',
      url: 'u-consultation/icd/search',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      params: { diagnosis: `"${props.diagnosis}"`, page: 1, limit: 10 }
    }).then((res) => {
      if (res.data.length === 0) {
        notification.warning({
          placement: 'bottomRight',
          description: "didn't find any code",
          message: "didn't find this any codes code"
        });
      }
      setloading(false);
      setIcdData(res.data);
    });
  };
  const onSelectIcd = (value) =>{
// form.setFieldsValue({
//   GeneralSymptoms: form.getFieldValue('GeneralSymptoms')
//     ? form.getFieldValue('GeneralSymptoms') +
//       '=================================<br>' +
//       value[0]?.GeneralSymptoms
//     : value[0]?.GeneralSymptoms,
//   SpecificSymptoms: form.getFieldValue('SpecificSymptoms')
//     ? form.getFieldValue('SpecificSymptoms') +
//       '=================================<br>' +
//       value[0]?.SpecificSymptoms
//     : value[0]?.SpecificSymptoms,
//   RiskIndex: value[0]?.RiskIndex,
//   ListOfReferences: form.getFieldValue('ListOfReferences')
//     ? form.getFieldValue('ListOfReferences') +
//       '=================================<br>' +
//       value[0]?.ListOfReferences
//     : value[0]?.ListOfReferences,
//   Icd10Code: [...form.getFieldValue('Icd10Code') , value[0]?.ChildCode]
// });
  
setIcdData(oldArray => [...oldArray,value[0]]);
// const newIcdData = icdData
// upsert(newIcdData , )
// setIcdData([...newIcdData])
  }
  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      onOk={() => {
        props.handleFinish(form);
        handleClose();
      }}
      cancelText='cancel'
      okText='fetch'
      className='Details'
    >
      {props.mode === 'diagnosis' ? (
        <Row>
          <Col span={12} style={{ maxHeight: '68vh', overflow: 'scroll' }}>
            <h2>
              {`${props.code} - ${props.diagnosis}`}
              <a
                href={`https://www.google.com/search?q=${props.diagnosis + ' symptoms'}`}
                target='_blank'
              >
                <img src='/content/google.png' alt='google' style={{ width: '20px' }} />{' '}
              </a>
            </h2>
            <Icdsearch onSelect ={onSelectIcd}/>
            {loading ? (
              <Spin />
            ) : icdData.length > 0 ? (
              <Checkbox.Group onChange={handleChange} value={currentCodes}>
                {codes}
              </Checkbox.Group>
            ) : (
              <>
                <p>we didn't find any code related to this diagnosis</p>
                <button onClick={handleSearchFullText}>search again</button>
              </>
            )}
            <div>
              <h2>Selected Code</h2>
              {currentCodes.map((code) => (
                <p>{code}</p>
              ))}
            </div>
          </Col>
          <Col span={12} style={{ maxHeight: '68vh', overflow: 'scroll' }}>
            <Button
              onClick={() => {
                props.handleFinish(form);
                handleClose();
              }}
              style={{ float: 'right' }}
              type='primary'
            >
              fetch
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
              }}
              style={{ float: 'right', marginRight: '10px' }}
              danger
            >
              Clear
            </Button>
            <div style={{ clear: 'both' }}></div>
            <FetchForm form={form} />
          </Col>
        </Row>
      ) : (
        <FetchForm form={form} />
      )}
    </Modal>
  );
};

export default FetchfromIcdModal;
