import { Button, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { datasource } from './mathData';

const MathFormulaModal = () => {
  const [visible, setvisible] = useState(false);

  const columns = [
    {
      title: 'Operator',
      dataIndex: 'Operator',
      key: 'Operator'
    },
    {
      title: 'Associativity',
      dataIndex: 'Associativity',
      key: 'Associativity'
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description'
    }
  ];

  return (
    <>
      <Button
        type='default'
        style={{
          position: 'fixed',
          right: '20px',
          backgroundColor: '#fff',
          zIndex: 2000
        }}
        onClick={() => setvisible(true)}
      >
        Math guid
      </Button>

      <Modal visible={visible} onOk={() => setvisible(false)} onCancel={() => setvisible(false)}>
        <Table dataSource={datasource} columns={columns} />
        <p style={{ marginTop: '20px' }}>
          for more info please click{' '}
          <a href='https://github.com/silentmatt/expr-eval' target='_blank'>
            here
          </a>{' '}
        </p>
      </Modal>
    </>
  );
};

export default MathFormulaModal;
