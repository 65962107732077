import {
  SET_USERS,
  FAIL_ADD,
  FAIL_SET,
  SUCCESSED_ADD,
  ADDING_USER,
  CLOSE_MODALE,
  EDITING_USER,
  SHOW_USER
} from './types';
import * as actionType from './actiontype';
import axios from 'axios';

export const setUsers = (users, total) => {
  return {
    type: actionType.SET_UDE_USERS,
    users: users,
    total: total
  };
};
export const failSetUsers = () => {
  return {
    type: actionType.FAIL_SET_USERS
  };
};
export const failAdd = (error) => {
  return {
    type: actionType.FAIL_ADD_UDE_USER,
    error: error
  };
};
export const successedAdd = () => {
  return {
    type: actionType.SUCCESSED_ADD_UDE_USER
  };
};
export const addinguser = () => {
  return {
    type: actionType.ADDING_UDE_USER
  };
};
export const closeModal = () => {
  return {
    type: actionType.CLOSE_MODALE_UDE_USER
  };
};
export const edit = () => {
  return {
    type: actionType.CLOSE_MODALE_UDE_USER
  };
};
export const show = (user) => {
  return {
    type: SHOW_USER,
    user: user
  };
};

export const getUsers = (page, filters?: object) => {
  return (dispatch) => {
    axios({
      method: 'GET',
      url: '/u-consultation/ude-user/list/ude-user',
      params: { page: page, limit: 10, ...filters },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    })
      .then((resp) => {
        dispatch(setUsers(resp.data.docs, resp.data.totalDocs));
      })
      .catch((err) => {
        dispatch(failSetUsers());
      });
  };
};
export const addUser = (data) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: '/u-consultation/superadmin/add/user',
      data: { ...data },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    })
      .then((resp) => {
        dispatch(successedAdd());
      })
      .catch((err) => {
        dispatch(failAdd(err.response.data.message));
        console.log(err.response.data.message);
      });
  };
};
export const editUser = (id, data) => {
  return (dispatch) => {
    axios({
      method: 'PUT',
      url: `/u-consultation/ude-user/user/${id}`,
      data: {
        ...data
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    }).then((res) => {
      dispatch(getUsers(1));
    });
  };
};
export const switchUser = (id, isActive) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: '/u-consultation/superadmin/switch/user',
      data: {
        id: id,
        isActive: isActive
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      dispatch(getUsers);
    });
  };
};
