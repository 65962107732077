import React, { useState, useEffect } from 'react';
import MailData from '../../../assets/data/mail.data.json';
import { ReplySVG } from '../../../assets/svg/icon';
import { labels, getFileType } from './MailLabels';
import AvatarStatus from '../../../shared-components/AvatarStatus';
import { Avatar, Button, Form, Input, Tooltip } from 'antd';
import {
  LeftCircleOutlined,
  StarOutlined,
  DeleteOutlined,
  StarFilled,
  DownloadOutlined
} from '@ant-design/icons';
import CustomIcon from '../../../util-components/CustomIcon';
import Axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import moment from 'moment';

const MaiDetail = (props) => {
  const [email, setEmail] = useState({
    label: null,
    starred: [],
    replies: [
      {
        admin: [
          {
            _id: null,
            username: null,
            email: null
          }
        ],
        subject: null,
        message: null
      }
    ],
    _id: null,
    title: 'mr',
    specialty: { mainSpeciality: { en: null } },
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    message: null,
    country: null,
    createdAt: null
  });
  const [user, setUser] = useState({ _id: null });
  const getUser = () =>
    Axios({
      method: 'GET',
      url: 'u-consultation/auth/me',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      setUser(res.data.data);
    });
  const history = useHistory();
  const params: any = useParams();
  const getMessage = () => {
    Axios({
      method: 'GET',
      url: `contact-us/messages/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      setEmail(res.data);
    });
  };
  useEffect(() => {
    getMessage();
    getUser();
  }, []);
  console.log(email.starred.includes(user._id), user);
  const tick = () => {
    Axios({
      method: 'POST',
      url: `contact-us/star/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(() => {
      getMessage();
    });
  };

  const back = () => {
    history.goBack();
  };
  console.log(moment('2021-12-07T00:33:38.757Z').toString());
  const deletMessage = () => {
    Axios({
      method: 'DELETE',
      url: `/contact-us/messages/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(() => {
      back();
    });
  };
  const handlereply = (values) => {
    Axios({
      method: 'POST',
      url: `/contact-us/reply/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      data: values
    }).then(() => {
      getMessage();
    });
  };
  const { firstName, lastName, title, createdAt, message, specialty, replies } = email;

  return (
    <div className='mail-detail'>
      <div className='d-lg-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center mb-3'>
          <div
            className='font-size-md mr-3'
            onClick={() => {
              back();
            }}
          >
            <LeftCircleOutlined className='mail-detail-action-icon font-size-md ml-0' />
          </div>
        </div>
        <div className='mail-detail-action mb-3'>
          <span className='mr-2 font-size-md'>{moment(createdAt).format('LLLL').toString()}</span>
          <Tooltip title='Reply'>
            <CustomIcon />
          </Tooltip>
          <Tooltip title='Star'>
            {email.starred.includes(user._id) ? (
              <StarFilled className='mail-detail-action-icon star checked' onClick={tick} />
            ) : (
              <StarOutlined className='mail-detail-action-icon star' onClick={tick} />
            )}
          </Tooltip>
          <Tooltip title='Delete'>
            <DeleteOutlined className='mail-detail-action-icon' onClick={deletMessage} />
          </Tooltip>
        </div>
      </div>
      <div className='mail-detail-content'>
        <h3 className='mb-4'>{title + ' / ' + firstName + ' ' + lastName}</h3>
        <h6>Speciality : {specialty.mainSpeciality.en}</h6>
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <div className='mail-detail-attactment'>
          {/* {attachment.map((elm, i) => (
              <div className='mail-detail-attactment-item' key={`attachment-file-${i}`}>
                <span>{getFileType(elm.type)}</span>
                <div className='ml-2'>
                  <div>{elm.file}</div>
                  <div className='text-muted font-size-sm'>{elm.size}</div>
                </div>
              </div>
            ))} */}
        </div>
        <div className='replies'>
          {replies.map((reply) => {
            return (
              <div>
                <div className='reply-header'>
                  <Avatar src='/content/logo.png' /> <h5>{reply.admin[0].username}</h5>
                </div>
                <p> subject : {reply.subject}</p>
                <div dangerouslySetInnerHTML={{ __html: reply.message }}></div>
              </div>
            );
          })}
        </div>
        <div className='reply-form'>
          <Form onFinish={handlereply}>
            <Form.Item name='subject'>
              <Input placeholder='Subject:' />
            </Form.Item>
            <Form.Item name='message'>
              <Input.TextArea />
            </Form.Item>
            <div className='submit-btn'>
              <Button type='primary' htmlType='submit'>
                reply
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default MaiDetail;
