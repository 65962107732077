export const SET_ICD11 = 'SET_ICD11';
export const FAIL_ADD_ICD11 = 'FAIL_ADD_ICD11';
export const SUCCESSED_ADD_ICD11 = 'SUCCESSED_ADD_ICD11';
export const FAIL_SET_ICD11 = 'FAIL_SET_ICD11';
export const ADDING_ICD11 = 'ADDING_ICD11';
export const CLOSE_MODALE = 'CLOSE_MODALE';
export const EDITING_USER = 'EDITING_ICD';
export const SHOW_ICD_ICD11 = ' SHOW_ICD_ICD11';
export const UPDATED_ICD11 = 'UPDATED_ICD11';
export const NEXT_ICD11 = 'NEXT_ICD11';
export const PERVIOS_ICD11 = 'PERVIOS_ICD11';
export const MORE_PAGES_ICD11 = 'MORE_PAGES_ICD11';
