import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Goback = (props) => {
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };
  return (
    <Button onClick={handleBack} className='backButton'>
      {props.text}
    </Button>
  );
};

export default Goback;
