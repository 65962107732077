import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';
import IcdsTable from './icdCmTable';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';
import UsersForm from './icdCmaddForm';

import { usePageData } from '../../../hooks/usePage';
import { getIcds, addingIcd, closeModal, addIcd } from '../../../redux/icd11/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Axios from 'axios';

const IcdCmPage = (props) => {
  const pageData: IPageData = {
    title: `ICD Cm / page ${props.page}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'ICD 11'
      }
    ]
  };
  const [icds, setIcds] = useState([]);
  usePageData(pageData);
  useEffect(() => {
    Axios({
      method: 'GET',
      url: '/u-consultation/icd_cm',
      params: { page: 1, limit: 10 },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      setIcds(res.data.docs);
    });
  }, []);
  const history = useHistory();
  const onEditIcd = (icd) => props.onAddIcd(icd);
  return (
    <>
      <IcdsTable onEditIcd={onEditIcd} />
      <PageAction onClick={() => history.push('/AddingIcdCm')} icon='icofont-contact-add' />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    addingIcd: state.icd11.icds,
    icds: state.icd11.icds,
    icdTable: state.icd11.icds,
    page: state.icd11.page
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetIcds: (page) => dispatch(getIcds(page)),
    onAddingIcd: () => dispatch(addingIcd()),
    closeModal: () => dispatch(closeModal()),
    onAddIcd: (icd) => dispatch(addIcd(icd))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IcdCmPage);
