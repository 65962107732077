import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';

import { usePageData } from '../../../hooks/usePage';
import { getIcds, addingIcd, closeModal, addIcd } from '../../../redux/icd11/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import BiosystemTable from './biosystemTable';
import Axios from 'axios';
const BiosystemPage = (props) => {
  const pageData: IPageData = {
    title: `biosystem`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'biosystem',
        route: 'biosystem'
      },
      {
        title: 'biosystem'
      }
    ]
  };
  usePageData(pageData);

  const history = useHistory();
  return (
    <>
      <BiosystemTable />
      <PageAction onClick={() => history.push('/AddingBiosystem')} icon='icofont-contact-add' />
    </>
  );
};
export default BiosystemPage;
