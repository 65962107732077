export const SET_UDE_USERS = 'SET_UDE_USERS';
export const FAIL_SET_USERS = 'FAIL_SET_UDE_USERS';

export const FAIL_ADD_UDE_USER = 'FAIL_ADD_UDE_USER';

export const SUCCESSED_ADD_UDE_USER = 'SUCCESSED_ADD_UDE_USER';

export const ADDING_UDE_USER = 'ADDING_UDE_USER ';

export const CLOSE_MODALE_UDE_USER = 'CLOSE_MODALE_UDE_USER ';
