export const datasource = [
  {
    key: Math.random() * 1000,
    Operator: '(...)',
    Associativity: 'none',
    Description: 'Grouping'
  },
  {
    key: Math.random() * 1000,
    Operator: 'f(), x.y, a[i]',
    Associativity: 'left',
    Description: 'Function call, property access, array indexing'
  },
  {
    key: Math.random() * 1000,
    Operator: '!',
    Associativity: 'left',
    Description: 'Factorial'
  },
  {
    key: Math.random() * 1000,
    Operator: '^',
    Associativity: 'Right',
    Description: 'Exponentiation'
  },
  {
    key: Math.random() * 1000,
    Operator: 'sqrt x',
    Associativity: 'none',
    Description: 'Square root of x. Result is NaN (Not a Number) if x is negative.'
  },
  {
    key: Math.random() * 1000,
    Operator: 'x ? y : z',
    Associativity: 'Right',
    Description: 'Ternary conditional (if x then y else z)'
  },
  {
    key: Math.random() * 1000,
    Operator: 'or',
    Associativity: 'none',
    Description: 'Logical OR'
  },
  {
    key: Math.random() * 1000,
    Operator: 'and',
    Associativity: 'none',
    Description: 'Logical AND'
  },

  {
    key: Math.random() * 1000,
    Operator: '==, !=, >=, <=, >, <, in',
    Associativity: 'left',
    Description:
      'Equals, not equals, etc. "in" means "is the left operand included in the right array operand?"'
  },
  {
    key: Math.random() * 1000,
    Operator: ';',
    Associativity: 'none',
    Description: 'Expression separator'
  },
  {
    key: Math.random() * 1000,
    Operator: 'pow(x, y)',
    Associativity: 'none',
    Description: "Equivalent to x^y. For consistency with JavaScript's Math object."
  },
  {
    key: Math.random() * 1000,
    Operator: 'min(a,b,…)',
    Associativity: 'none',
    Description: 'Get the smallest (minimum) number in the list.'
  },

  {
    key: Math.random() * 1000,
    Operator: 'max(a,b,…)',
    Associativity: 'none',
    Description: 'Get the largest (maximum) number in the list.'
  },
  {
    key: Math.random() * 1000,
    Operator: 'floor x',
    Associativity: 'none',
    Description: 'Floor of x — the largest integer that’s <= x'
  },
  {
    key: Math.random() * 1000,
    Operator: 'ceil x',
    Associativity: 'none',
    Description: 'Ceiling of x — the smallest integer that’s >= x'
  }
];
