import React, {useState} from 'react';

import { Button, Form, Input, Switch , Alert } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import {connect} from 'react-redux'
import * as action from '../../redux/auth/action'
import {useDispatch} from 'react-redux'

import PublicLayout from '../../layout/public/Public';
// import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
// import { navigateHome } from '../../utils/naviagate-home';

const { Item } = Form;



const ForgetPassword = (props) => {
  const history = useHistory();
  const [sent , setSent] = useState(false)
  const [phone , setPhone] = useState()
  const [error , setError] = useState(false)
  const [message , setmessage] = useState()
const handle = (phoneNumber) => {
  axios({
    method : "POST",
    url : '/u-consultation/auth/resend-phone-otp',
    data : {...phoneNumber}
  }).then((resp) => {
    console.log(resp)
    setSent(true)
    setPhone(phoneNumber.phoneNumber)
  }).catch((err) => {
    setError(true)
    setmessage(err.response.data.message)
  })
}

  const [form] = useForm();
  const disptch = useDispatch()
  const handleForget = () => {
    form
      .validateFields()
      .then(() =>  handle(form.getFieldsValue()))
      .catch(() => null);
  };

  const handleCode = (code) => {
    disptch(action.auth({type:"OTP", username: `${phone}` , password:`${code.code}`}));
    disptch(action.forgetPassword())
    history.push('/changePassword')
  }



  const sendCode = () => {
    form
      .validateFields()
      .then(() =>  handleCode(form.getFieldsValue()))
      .catch(() => null);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/shared.svg`}>
      <h4 className='mt-0 mb-1'>forget password</h4>
      <p className='text-color-200'>enter your Phone Number</p>
      {error ? <Alert type='error' message={message} style ={{margin: '20px'}} closable/> : null }
      {props.errormsg ? <Alert type='error' message={props.errormsg} style ={{margin: '20px'}} closable/> : null}
    {
      !sent ? <Form form={form} layout='vertical' className='mb-4'>
      <Item name='phoneNumber' rules={[{ required: true, message: <></> }]}>
        <Input placeholder='phoneNumber' />
      </Item>
      <Button
        block={false}
        type='primary'
        onClick={handleForget}
        htmlType ="submit"
      >
        send code 
      </Button>
    </Form> : <Form form={form} layout='vertical' className='mb-4'>
        <Item name='code' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='code' />
        </Item>
        <Button
          block={false}
          type='primary'
          onClick={sendCode}
          htmlType ="submit"
        >
          submit
        </Button>
      </Form>
    }
    </PublicLayout>
  );
};


export default ForgetPassword;
