import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Form,
  Collapse,
  Modal,
  notification,
  AutoComplete,
  Select
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router';
import { editIcd } from '../../../redux/icd11/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import IcdCart from './Icd11cart/icdCart';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import axios from 'axios';

import JoditEditor from 'jodit-react';
import FullPageLoading from '../../components/FullPageLoading';
import { emptyIcd11, Icd11Keys } from '../common/icd11Types';

import Axios from 'axios';
import Icdsearch from './Icdsearch';
import FetchfromIcdBottom from '../../components/fetchfromIcd10/fetchfromIcdBottom';
import Goback from '../../../layout/components/goBack/goback';
import FetchfromIcdModal from '../../components/fetchfromIcd10/fetchfromIcdBottom';
import FormController from '../../components/formController/formController';
const { Panel } = Collapse;
const { Item } = Form;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    Axios({
      method: 'GET',
      url: 'u-consultation/icd',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      params: { ChildCode: value }
    }).then((res) => {
      callback(res.data.docs);
    });
  }

  timeout = setTimeout(fake, 300);
}
const Icd11Detail = () => {
  const pageData: IPageData = {
    title: `Icd 11  - page (last page you visit) : ${localStorage.getItem('icd11Page') || 1} `,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'ICD 10'
      }
    ]
  };
  const [icd, setIcd] = useState(emptyIcd11);
  const [loading, setloading] = useState(true);
  const params: { id: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    axios({
      method: 'GET',
      url: `u-consultation/icd11/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log('icd res', res.data);
      setIcd({ ...res.data });
      res.data.Icd10Code && handleIcdSearch(res.data.Icd10Code);
      icd11Form.setFieldsValue(res.data);
      setloading(false);
      console.log(icd);
    });
  }, [history.location.pathname]);

  usePageData(pageData);
  const [icd11Form] = useForm();
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    icd11Form
      .validateFields()
      .then(() => dispatch(editIcd(values._id, icd11Form.getFieldsValue())))
      .catch(() => null);
  };
  const handleSelect = (data) => {
    setData(data);
  };

  const { Search } = Input;

  const handleSearch = (value) => {
    axios({
      method: 'GET',
      url: 'u-consultation/icd11',
      params: { ChildCode: value }
    }).then((res) => {
      if (res.data.docs.length > 0) {
        history.push(`${res.data.docs[0]._id}`);
        // icd11Form.setFieldsValue(res.data.docs[0]);
      } else {
        notification.warning({
          placement: 'bottomRight',
          description: "didn't find this code",
          message: "didn't find this code"
        });
      }
    });
  };
  const DeletIcd = () => {
    axios({
      method: 'DELETE',
      url: `u-consultation/icd11/${icd._id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
      .then(() => {
        notification.success({
          placement: 'bottomRight',
          description: 'deleted',
          message: 'this icd is deleted for ever'
        });
      })
      .then(() => {
        history.goBack();
      })
      .catch((err) => {
        notification.error({
          placement: 'bottomRight',
          description: 'some thing went wrong',
          message: 'please try again later'
        });
      });
  };
  const handleDeleteIcd = () => {
    Modal.warning({
      title: `do you need to delete this Icd ${icd.ChildCode}`,
      content: 'by click on delete you will loose this icd forever be carful please',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => DeletIcd(),
      okCancel: true,
      cancelText: 'Cancel'
    });
  };

  const [data, setData] = useState([]);

  const handleIcdSearch = (value) => {
    if (value) {
      fetch(value, (data) => setData(data));
    } else {
      setData([]);
    }
  };

  const handleFetching = async () => {
    await icd11Form.setFieldsValue({
      GeneralSymptoms: data[0]?.GeneralSymptoms,
      SpecificSymptoms: data[0]?.SpecificSymptoms,
      RiskIndex: data[0]?.RiskIndex,
      ListOfReferences: data[0]?.ListOfReferences
    });
  };

  const handleFinshFetch = (form) => {
    const values = form.getFieldsValue();
    icd11Form.setFieldsValue({
      GeneralSymptoms: values?.GeneralSymptoms,
      SpecificSymptoms: values?.SpecificSymptoms,
      RiskIndex: values?.RiskIndex,
      ListOfReferences: values?.ListOfReferences,
      Icd10Codes: values?.Icd10Code
    });
  };
  return (
    <>
      {loading ? (
        <>
          <FullPageLoading />{' '}
        </>
      ) : (
        <div>
          <IcdCart result={icd} />
          <Form form={icd11Form} onFinish={handleSubmit} layout='vertical'>
            <Form.Item label='_id' name='_id' style={{ display: 'none' }}>
              <Input type='text' />
            </Form.Item>

            <FormController
              form={icd11Form}
              childCode={icd.ChildCode}
              id={icd._id}
              handleSearch={handleSearch}
              handleDeleteIcd={handleDeleteIcd}
            />
            <Collapse
              defaultActiveKey={[
                'GeneralSymptoms',
                'SpecificSymptoms',
                'ListOfReferences',
                'Icd10Codes',
                'RiskIndex'
              ]}
            >
              {Icd11Keys.map((item) => {
                return (
                  <Panel header={item} key={item}>
                    <div className='form-group'>
                      {item === 'ChildCode' ? (
                        <Form.Item name={item}>
                          <Input type='text' />
                        </Form.Item>
                      ) : item === 'Male' ||
                        item === 'Female' ||
                        item === 'Poisoning' ||
                        item === 'Accident' ||
                        item === 'ChronicDisease' ||
                        item === 'RiskIndex' ||
                        item === 'fetus_first' ||
                        item === 'fetus_secand' ||
                        item === 'fetus_third' ||
                        item === 'newborn' ||
                        item === 'm1_y5' ||
                        item === 'y5_15' ||
                        item === 'y15_25' ||
                        item === 'y25_45' ||
                        item === 'above65y' ? (
                        <Form.Item name={item} noStyle>
                          <InputNumber
                            max={item === 'RiskIndex' ? 10 : 1}
                            min={0}
                            style={{ width: '100%', marginBottom: '10px' }}
                            placeholder={item}
                            maxLength={1}
                          />
                        </Form.Item>
                      ) : item === 'Icd10Codes' ? (
                        <Form.Item name={item}>
                          <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            placeholder='Please select'
                          ></Select>
                        </Form.Item>
                      ) : (
                        <Item name={item}>
                          <JoditEditor value='' />
                        </Item>
                      )}
                    </div>
                  </Panel>
                );
              })}
            </Collapse>
           
          </Form>
          <FetchfromIcdModal
            mode='diagnosis'
            className='fetchButton'
            diagnosis={icd.Diagnosis}
            code = {icd.ChildCode}
            currentCodes={icd11Form.getFieldValue('Icd10Codes')}
            handleFinish={(form) => handleFinshFetch(form)}
          />
        </div>
      )}
    </>
  );
};

export default Icd11Detail;
