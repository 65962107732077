import { IRoute } from '../interfaces/routing';
import React from 'react';
import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import SignIn from '../pages/sessions/Sign-in';
import SignUp from '../pages/sessions/Sign-up';
import forgetPassword from '../pages/sessions/forgetPassword';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
function sessionRoutes() {
  return (
    <>
      {' '}
      <Router>
        <Switch>
          <Route path='/page-404' component={NotFound} />
          <Route path='/page-500' component={InternalError} />
          <Route path='/sign-in' component={SignIn} />
          <Route path='/forgot-password' component={forgetPassword} />
          <Route path='/sign-up' component={SignUp} />
          <Redirect to='/sign-in' />
        </Switch>
      </Router>
    </>
  );
}
export default sessionRoutes;
