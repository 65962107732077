import React , {useState} from 'react';
import { Button,Input ,Select  , Form, Alert,Row , Col,Switch , Checkbox} from 'antd';
import { useForm } from 'antd/es/form/Form';
import {editUser} from '../../../redux/users/action'
import {connect} from 'react-redux';
import { useHistory } from 'react-router';

const FormItem = Form.Item;
const Option = Select.Option;

const children = [
  <Option value = "clinic" > clinic </Option>,
  <Option value = "medical center"> medical center </Option>,
  <Option value = "pharmacy"> pharmacy </Option>
]
const emptyUser = {
    appliedInviationCode: null,
    devices: [],
    email: null,
    hasAppliedInviationCode: null,
    isActive: null,
    isEmailVerified: null,
    isPhoneVerified: null,
    isVerified: null,
    language: null,
    linkedAccounts: [null],
    loginAttempts: null,
    otp: {hasUsed: null, createdAt: null, type: null, code: null},
    phoneNumber: "201207973067",
    role: null,
    sentInvitationsCount: null,
    sentInvitationsCountLimited: null,
    username :null,
    _id :null
} 
const UsersForm = ({user  = emptyUser , onEdite ,error , onCancel ,submitText , editing}) => {
  
  const [form] = useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() =>  onEdite( user._id,form.getFieldsValue()))
      .catch(() => null);
  };
  const history = useHistory()

  const handleBack = () => {
    history.goBack()
  };
  const [statu , setStatu] = useState(user.isActive)
  const handleChange =()=>{
    // props.onswitch(user._id , !user.isActive)
    setStatu(!statu)
  }
console.log('form' , user)
  return (
    <div>
    <Form  form={form} layout='vertical' initialValues = {user}>
        <Row style = {{justifyContent : "space-between"}}>
        <Col span = {11}>
            <FormItem name='username' label='Full name'>
                    <Input  placeholder='Full name' />
            </FormItem>
        </Col>
        <Col span = {8}>
      <FormItem  name='phoneNumber' label='Phone' >
        <Input  placeholder='Phone' readOnly/>
      </FormItem>
      </Col>
      <Col span = {3}>
      <FormItem name ="isPhoneVerified" label='isPhoneVerified'>
      <Checkbox defaultChecked ={user.isPhoneVerified}/>
      </FormItem>
      </Col>
    
      <Col span = {11}>
      <FormItem name = "role" label ="role">
          <Select >
          {children}
          </Select>
      </FormItem>
      </Col>
      <Col span = {8}>
      <FormItem  name = "email" label='email'>
        <Input  placeholder='email' />
      </FormItem>
      </Col>
     <Col span ={3}>
      <FormItem name = "isEmailVerified" label='isEmailVerified'>
      <Checkbox defaultChecked ={user.isEmailVerified}/>
      </FormItem>
      </Col>
     <Row style = {{justifyContent : "space-between"}}>
         <Col span = {15}>
      <FormItem name ="sentInvitationsCount" label='sentInvitationsCount'>
      <Input  type ="number" placeholder='sentInvitationsCount'/>
      </FormItem>
         </Col>
         <Col span={8}>
      <FormItem name = "CountLimited" label='CountLimited'>
      <Checkbox defaultChecked ={user.sentInvitationsCountLimited} />
      </FormItem>
      </Col>
      </Row>
      <Col span = {3}>
      <FormItem name = "isActive" label='Status'>
      <Switch checked ={statu} onChange = {handleChange}/>
      </FormItem>
      </Col>
      <Col span={3}>
      <FormItem name = "isVerified" label='isVerified'>
      <Checkbox defaultChecked ={user.isVerified}/>
      </FormItem>
      </Col>
    </Row>
      <Row style = {{justifyContent : "space-between"}}>
      <Col span = "11">
       <Button type = "link" onClick ={handleBack}> Back </Button>
      </Col>
      <Col span = "11">
       <Button type ="primary" htmlType ="submit" onClick = {handleSubmit} style ={{marginLeft : "80%"}} >save Change</Button>
      </Col>
       
      </Row>
    </Form>
     </div>
  );
};
const mapStateToProps = state => {
  return {
    error : state.users.error,
    user : state.users.user,
    editing: state.users.editing
  }
}
const mapDispatchToProps = (dispatch) => {
    return{
        onEdite : (id, data) => dispatch(editUser(id , data))
    }
}
export default connect(mapStateToProps , mapDispatchToProps)(UsersForm);
