export const IcdCmKeys = [
  'GeneralSymptoms',
  'SpecificSymptoms',
  'System',
  'Icd10Codes',
  'ListOfReferences',
  'RiskIndex',
  'ParentBillableCode',
  'ChildBillableCode',
  'DiagnosisVariances',
  'ClincalSigns',
  'LaboratoryDiagnosis',
  'RadiologyDiagnosis',
  'DiagnosticTest',
  'WhenToSeeDoctor',
  'LaboratoryInvestigations',
  'RadiologyScans',
  'PlanOfCare',
  'RecommendedMedications',
  'RecommendedTherapy',
  'MedicalAndNursingRecommendations',
  'PrecautionsAndPrevention',
  'AssociatedMedicalConditions',
  'PregnancySpecialPrecautions',
  'ChapterCode',
  'ParentCategory',
  'ChildCategory',
  'ParentCode',
  'ChildCode',
  'RelatedSpecialty',
  'Diagnosis',
  'ChronicDisease',
  'Accident',
  'Poisoning',
  'Male',
  'Female',
  'AgeGroup'
];
export const AgeGroup = [
  'FetusFirstTrimester',
  'FetusSecondTrimester',
  'FetusThirdTrimester',
  'FetusPostTerm',
  'Newborn',
  'm1_5y',
  'y5_15',
  'y15_25',
  'y25_45',
  'y45_65',
  'above65y'
];
