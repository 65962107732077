import React, { useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import Axios from 'axios';

const Item = Form;
const { Option } = Select;

const Icdsearch = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  let timeout;
  let currentValue;
useEffect(() =>{
setValue(undefined)
},[])
  function fetch(value, callback) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    function fake() {
      setLoading(true)
      Axios({
        method: 'GET',
        url: 'u-consultation/icd',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { ChildCode: value }
      }).then((res) => {
      setLoading(false)
        callback(res.data.docs);
      });
    }

    timeout = setTimeout(fake, 300);
  }
  const handleChange = (value) => {
    setValue(value);
  };
  const handleIcdSearch = (value) => {
    if (value) {
      fetch(value, (data) => setData(data));
    } else {
      setData([]);
    }
  };

  const options = data.map((d) => (
    <Option value={d.ChildCode} key={d.ChildCode}>
      {d.ChildCode}
    </Option>
  ));
  return (
    <>
      <Form.Item name='Icd10Code'>
        <Select
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          placeholder="please write Icd10 code"
          onSelect={() => props.onSelect(data)}
          filterOption={false}
          onSearch={handleIcdSearch}
          onChange={handleChange}
          notFoundContent={loading? <Spin /> :<p>we didn't find any code</p>}
        >
          {options}
        </Select>
      </Form.Item>
    </>
  );
};

export default Icdsearch;
