import React, { useEffect, useState } from 'react';
import { Progress, Modal, Button } from 'antd';
import './icdCart.scss';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch , useSelector} from 'react-redux';
import { getIcd } from '../../../../redux/icd/actions';

type Iprops = {
  result: any;
};
function IcdCart(props: Iprops) {
  const [currentIcd , setCurrrentIcd] = useState(1)
  const state = useSelector((state : any) => state.icd)
  const dispatch = useDispatch()
  // useEffect(() => {
  //   setCurrrentIcd(state.icds.findIndex(icd => icd.ChildCode === state.icd.ChildCode) + 1)
  // } , [])
  const handleNext = () => {
    dispatch(getIcd(currentIcd + 1))
    setCurrrentIcd(currentIcd + 1)
  }
  const handlePrevious = () => {
    dispatch(getIcd(currentIcd - 1)) 
    setCurrrentIcd(currentIcd - 1 )
  }
  return (
    <div className='Icd-cart'>
     
        {/* <Button onClick = {() =>  handlePrevious()}> <ArrowLeftOutlined /></Button> */}
     
      <div className = "icd-info">
      <div className = "item">
      <span>Child code : </span>
      <strong dangerouslySetInnerHTML={{ __html: props.result.ChildCode }}></strong>
      <a href = {`https://www.google.com/search?q=${props.result.ChildCode + " symptoms"}`} target = "_blank" > <img src = "/content/google.png" alt= "google" style ={{width : "20px"}}/> </a>
      </div>
     <div className = "item">
     <span>Diagnosis : </span>
      <strong dangerouslySetInnerHTML={{ __html: props.result.Diagnosis }}></strong>
      <a href = {`https://www.google.com/search?q=${props.result.Diagnosis + " symptoms"}`} target = "_blank" > <img src = "/content/google.png" alt= "google" style ={{width : "20px"}}/> </a>
     </div>
      </div>
    
    {/* <Button onClick = {() => handleNext()}><ArrowRightOutlined /></Button> */}
  
    </div>
  );
}

export default IcdCart;
