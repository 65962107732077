import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';
import IcdsTable from './icdsTable';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';
import UsersForm from './addForm';

import { usePageData } from '../../../hooks/usePage';
import { getIcds, addingIcd, closeModal, addIcd } from '../../../redux/icd/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

const Icd10Page = (props) => {
  const pageData: IPageData = {
    title: `ICD 10 / page ${props.page}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'ICD 10'
      }
    ]
  };
  console.log(props);
  usePageData(pageData);
  useEffect(() => {
    props.onGetIcds(localStorage.getItem('icdPage'));
  }, []);
  const history = useHistory();
  const onEditIcd = (icd) => props.onAddIcd(icd);
  return (
    <>
      <IcdsTable onEditIcd={onEditIcd} icds={props.icds} />
      <PageAction onClick={() => history.push('/AddingIcd')} icon='icofont-contact-add' />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    addingIcd: state.icd.icds,
    icds: state.icd.icds,
    icdTable: state.icd.icds,
    page: state.icd.page
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetIcds: (page) => dispatch(getIcds(page)),
    onAddingIcd: () => dispatch(addingIcd()),
    closeModal: () => dispatch(closeModal()),
    onAddIcd: (icd) => dispatch(addIcd(icd))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Icd10Page);
