import React, { useState, useEffect } from 'react';
import { Button, Collapse, Form, Input, notification, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect, useDispatch } from 'react-redux';
import { addIcd } from '../../../redux/icd/actions';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import Axios from 'axios';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import TextArea from 'antd/lib/input/TextArea';
import { useParams } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Item } = Form;

const AddBiosystemForm = () => {
  const params: any = useParams();
  const pageData: IPageData = {
    title: `${params.editing ? 'biosystem' : 'Add biosystem'}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'biosystem',
        route: 'biosystem'
      },
      {
        title: 'Add biosystem'
      }
    ]
  };
  usePageData(pageData);
  const [form] = useForm();
  const [organs, setOrgans] = useState([]);
  const [icd10chapters, setIcd10chapters] = useState([]);
  const [icd11chapters, setIcd11chapters] = useState([]);

  const loadData = () => {
    Axios({
      method: 'GET',
      url: 'biosystems/organs/names'
    }).then((res) => {
      setOrgans(res.data);
    });
    Axios({
      method: 'GET',
      url: 'u-consultation/icd/chapters/names'
    }).then((res) => {
      setIcd10chapters(res.data);
    });
    Axios({
      method: 'GET',
      url: 'u-consultation/icd11/chapters/names'
    }).then((res) => {
      setIcd11chapters(res.data);
    });

    if (params.id) {
      Axios({
        method: 'GET',
        url: `biosystems/${params.id}`
      }).then((res) => {
        return form.setFieldsValue(res.data);
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        if (params.editing) {
          Axios({
            method: 'PUT',
            url: `biosystems/${params.id}`,
            data: { ...form.getFieldsValue() }
          }).then((res) => {
            notification.success({
              message: 'biosystem successfully updated'
            });
          });
        } else {
          Axios({
            method: 'POST',
            url: 'biosystems',
            data: { ...form.getFieldsValue() }
          }).then((res) => {
            notification.success({
              message: 'biosystem successfully added'
            });
          });
        }
      })
      .catch(() => null);
  };
  const history = useHistory();
  const handleCancel = () => {
    history.goBack();
  };
  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <Collapse>
          <Panel header='name' key='name'>
            <div className='form-group'>
              <Item name='name'>
                <Input placeholder='type name' />
              </Item>
            </div>
          </Panel>
          <Panel header='longName' key='longName'>
            <div className='form-group'>
              <Item name='longName'>
                <Input placeholder='type longName' />
              </Item>
            </div>
          </Panel>

          <Panel header='description' key='description'>
            <div className='form-group'>
              <Item name='description'>
                <TextArea placeholder='type description' />
              </Item>
            </div>
          </Panel>
          <Panel header='organs' key='organs'>
            <div
              className='form-group'
              style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}
            >
              <Form.List name='organs'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                          marginLeft: 10,
                          marginRight: 10
                        }}
                        align='baseline'
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          label='group name'
                          rules={[{ required: true, message: 'Missing group name' }]}
                        >
                          <Input placeholder='group name' />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'organ']}
                          label='organ Name'
                          rules={[{ required: true, message: 'Missing organ' }]}
                        >
                          <Input placeholder='organ Name' />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                        Add organ
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </Panel>
          <Panel header='icd10' key='icd10'>
            <div className='form-group'>
              <Item name='icd10'>
                <Select
                  mode='multiple'
                  placeholder='Please select icd10 chapter'
                  style={{ width: '100%' }}
                >
                  {icd10chapters.map((item) => {
                    return (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Item>
            </div>
          </Panel>
          <Panel header='icd11' key='icd11'>
            <div className='form-group'>
              <Item name='icd11'>
                <Select
                  mode='multiple'
                  placeholder='Please select icd10 chapter'
                  style={{ width: '100%' }}
                >
                  {icd11chapters.map((item) => {
                    return (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Item>
            </div>
          </Panel>
        </Collapse>

        <div
          className='d-flex justify-content-between buttons-list settings-actions'
          style={{ width: '100%' }}
        >
          <Button type='link' onClick={handleCancel}>
            go back
          </Button>

          <Button type='primary' htmlType='submit'>
            {params.editing ? 'update' : 'add biosystem'}
          </Button>
        </div>
      </Form>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.users.error
  };
};

export default connect(mapStateToProps)(AddBiosystemForm);
