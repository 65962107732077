import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Collapse,
  Form,
  Alert,
  InputNumber,
  Row,
  Col,
  Space,
  Select,
  Slider,
  Radio,
  notification,
  Modal,
  AutoComplete
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect, useDispatch } from 'react-redux';
import { addIcd } from '../../../redux/icd/actions';
import { useHistory } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import JoditEditor from 'jodit-react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ChoiceForm from './choiceForm';
import Specialtyinput from '../../components/specialtyInput/specialtyinput';
import { Parser } from 'expr-eval';
import Axios from 'axios';
import MathFormulaModal from './mathFormula';

const { Panel } = Collapse;
const { Item } = Form;

const { TextArea } = Input;
const emptyformula = {
  _id: null,
  formula: null,
  formulaKeys: [
    {
      name: '',
      key: '',
      type: '',
      maxValue: '',
      minValue: '',
      choies: []
    }
  ],
  title: null,
  description: null,
  resultDescription: null,
  resultmeasuringUnit: null,
  formulaDetails: null,
  speciality: null,
  hashtags: null
};
const AddingFormulaForm = () => {
  const pageData: IPageData = {
    title: `Add formula`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'Add formula'
      }
    ]
  };

  usePageData(pageData);
  const Option = Select;
  const [form] = useForm();
  const [testForm] = useForm();
  const [tested, setTested] = useState(false);
  const [formula, setFormula] = useState(null);
  const [showTest, setShowTest] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    console.log(form.getFieldsValue());
    setFormula(form.getFieldsValue());
    !tested && setShowTest(true);
    tested &&
      Axios({ method: 'POST', url: 'formula', data: form.getFieldsValue() }).then(() => {
        return notification.success({
          placement: 'topRight',
          message: 'formula added successfully'
        });
      });
  };

  useEffect(() => {
    function calc_inc(pre_prop, LR) {
      const pre_odds = Math.round(pre_prop / (100 - pre_prop));
      const post_odds = Math.round(pre_odds * LR);
      const post_prop = Math.round((post_odds / (post_odds + 1)) * 100);
      const result = post_prop - pre_prop;
      return result;
    }

    function calc_total(lr_list: any, pre_prop) {
      console.log(lr_list);
      var total_inc = 0;
      for (let lr = 0; lr < lr_list.length; lr++) {
        console.log('1', lr_list[lr]);
        console.log('2', total_inc);
        total_inc += calc_inc(pre_prop, lr_list[lr]);
      }
      return pre_prop + total_inc;
    }
    console.log(calc_total([1.2, 1.4], 25));
  }, []);
  const handleTestSubmit = () => {
    const { expectedValue, ...values } = testForm.getFieldsValue();
    const parser = new Parser();

    function calc_inc(pre_prop, LR) {
      const pre_odds = pre_prop / (100 - pre_prop);
      const post_odds = pre_odds * LR;
      const post_prop = Math.round((post_odds / (post_odds + 1)) * 100);
      const result = post_prop - pre_prop;
      return result;
    }

    parser.functions.calc_total = function (lr_list: any, pre_prop) {
      console.log(lr_list);
      var total_inc = 0;
      for (let lr = 0; lr < lr_list.length; lr++) {
        console.log('1', lr_list[lr]);
        console.log('2', total_inc);
        total_inc += calc_inc(pre_prop, lr_list[lr]);
      }
      return pre_prop + total_inc;
    };
    const parsedFormula = parser.parse(formula.formula);
    const value = parsedFormula.evaluate(values);
    if (value == expectedValue) {
      setTested(true);
      setShowTest(false);
      notification.success({
        placement: 'topRight',
        message: 'formula is work successfully you can submit formula now'
      });
    } else {
      notification.warning({
        placement: 'bottomRight',
        message: `no match between result ${value} and expected value ${expectedValue}`
      });
    }
  };
  const history = useHistory();
  const handleCancel = () => {
    history.goBack();
  };
  const [sliderValue, setsliderValue] = useState();
  const handleSlideronChange = (value) => {
    setsliderValue(value);
  };
  const options = [{ value: 'calc_total(pre_prop , list)' }];

  return (
    <>
      <MathFormulaModal />
      <Form form={form} onFinish={handleSubmit} initialValues={emptyformula} layout='vertical'>
        <Collapse defaultActiveKey={['formula', 'title', 'formulaKeys']}>
          <Panel header='title' key='title'>
            <Form.Item
              name='title'
              rules={[{ required: true, message: 'Please input the title!' }]}
            >
              <Input />
            </Form.Item>
          </Panel>
          <Panel header='formula' key='formula'>
            <Form.Item
              name='formula'
              rules={[{ required: true, message: 'Please input the formula!' }]}
            >
              <AutoComplete
                options={options}
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input onChange={() => setTested(false)} />
              </AutoComplete>
            </Form.Item>
          </Panel>
          <Panel header='description' key='description'>
            <Form.Item name='description'>
              <JoditEditor value='' />
            </Form.Item>
          </Panel>

          <Panel header='formulaKeys' key='formulaKeys'>
            <Form.List name='formulaKeys'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Row key={field.key} justify='space-between'>
                      <Form.Item
                        {...field}
                        label='name'
                        name={[field.name, 'name']}
                        fieldKey={[field.fieldKey, 'name']}
                        rules={[{ required: true, message: 'Missing maxValue' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label='key'
                        name={[field.name, 'key']}
                        fieldKey={[field.fieldKey, 'key']}
                        rules={[{ required: true, message: 'Missing key' }]}
                      >
                        <Input onChange={() => setTested(false)} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label='type'
                        name={[field.name, 'type']}
                        fieldKey={[field.fieldKey, 'type']}
                        rules={[{ required: true, message: 'Missing type' }]}
                      >
                        <Select style={{ width: 130 }}>
                          <Option value='range'>range</Option>
                          <Option value='choices'>choices</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label='minValue'
                        name={[field.name, 'minValue']}
                        fieldKey={[field.fieldKey, 'minValue']}
                        rules={[{ message: 'Missing minValue' }]}
                      >
                        <Input type='number' />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label='maxValue'
                        name={[field.name, 'maxValue']}
                        fieldKey={[field.fieldKey, 'maxValue']}
                        rules={[{ message: 'Missing maxValue' }]}
                      >
                        <Input type='number' />
                      </Form.Item>

                      <Form.Item>
                        <ChoiceForm fieldKey={field.name} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Row>
                  ))}

                  <Form.Item>
                    <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                      Add key
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Panel>

          <Panel header='resultDescription' key='resultDescription'>
            <Form.Item name='resultDescription'>
              <Input />
            </Form.Item>
          </Panel>

          <Panel header='resultMeasuringUnit' key='resultMeasuringUnit'>
            <Form.Item name='resultMeasuringUnit'>
              <Input />
            </Form.Item>
          </Panel>

          <Panel header='formulaDetails' key='formulaDetails'>
            <Form.Item name='formulaDetails'>
              <Input />
            </Form.Item>
          </Panel>

          <Panel header='speciality' key='speciality'>
            <Specialtyinput />
          </Panel>
        </Collapse>
        <div
          className='d-flex justify-content-between buttons-list settings-actions'
          style={{ width: '100%' }}
        >
          <Button type='link' onClick={handleCancel}>
            go back
          </Button>

          <Button
            type={tested ? 'primary' : 'default'}
            style={{ backgroundColor: tested ? 'green' : null }}
            htmlType='submit'
          >
            {tested ? 'add Formula' : 'Test'}
          </Button>
        </div>
      </Form>
      <Modal
        visible={showTest}
        onOk={() => testForm.submit()}
        okText='test'
        onCancel={() => setShowTest(false)}
      >
        {showTest ? (
          <Form form={testForm} onFinish={handleTestSubmit}>
            {formula.formulaKeys.map((item) => {
              return (
                <Form.Item name={item.key} label={item.name}>
                  {item.type === 'range' ? (
                    <Slider
                      min={parseInt(item.minValue)}
                      max={parseInt(item.maxValue)}
                      onChange={handleSlideronChange}
                      tooltipVisible
                      value={typeof sliderValue === 'number' ? sliderValue : 0}
                    />
                  ) : (
                    <Radio.Group>
                      {item.choices &&
                        item.choices.map((item) => {
                          return <Radio value={item.value}>{item.name}</Radio>;
                        })}
                    </Radio.Group>
                  )}
                </Form.Item>
              );
            })}

            <Form.Item
              name='expectedValue'
              label='expectedValue'
              rules={[{ required: true, message: 'Please input expected value!' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

export default AddingFormulaForm;
