import React, { ReactNode } from 'react';
import './Public.scss';
import Logo from '../components/logo/Logo';
import umedmi from '../../assets/img/umedmi.png';

type Props = { children: ReactNode; bgImg?: string; transparent?: boolean };

const PublicLayout = ({ children, bgImg, transparent = false }: Props) => (
  <div className='public-layout' style={{ backgroundImage: `url(${bgImg})` , backgroundPositionX : "80%", backgroundSize : "50%" , backgroundRepeat: 'no-repeat'}}>
    <div className={`content-box ${transparent ? 'transparent' : null}`}>
      <div className='content-header'>
        <Logo src={umedmi} />
      </div>
      <div className='content-body'>{children}</div>
    </div>
  </div>
);

export default PublicLayout;
