import React, { useState, useEffect } from 'react';
import { Switch, Table, Button, Modal, Tag, Pagination, Select, Tooltip, Form } from 'antd';
import AdminForm from './commonaddForm';
import { editIcd, getIcds, getMoreIcds, show } from '../../../redux/icd11/actions';
import { connect, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import JoditEditor from 'jodit-react';
import { useForm } from 'antd/es/form/Form';
import Axios from 'axios';

const CommonDiagnosisTable = (props) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [common, setCommon] = useState([]);
  const [TotalCommon, setTotalCommon] = useState(0);
  const getbiosystem = (page = 1, limit = 10) => {
    setLoading(true);
    Axios({
      method: 'GET',
      url: '/u-consultation/commondiagnosis',
      params: { page, limit }
    }).then((res) => {
      setCommon(res.data.docs);
      setTotalCommon(res.data.totalDocs);
      setLoading(false);
    });
  };
  useEffect(() => {
    getbiosystem();
  }, []);

  const Actions = (common) => {
    const handleShow = () => {
      history.push(`/commonDetail/${common._id}`);
    };
    return (
      <div className='buttons-list nowrap'>
        <Button onClick={handleShow.bind({}, common)} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      </div>
    );
  };
  const [visibleEdite, setvisibleEdite] = useState(false);
  const [fieldEdited, setfieldEdited] = useState();
  const [IcdEdited, setIcdEdited] = useState({ _id: null });
  const [fieldValue, setFeildValue] = useState();
  const showModal = (fieldName, fieldValue, icd) => {
    setfieldEdited(fieldName);
    setIcdEdited(icd);
    setFeildValue(fieldValue);
    setvisibleEdite(true);
  };

  const handleEdite = (values) => {
    dispatch(editIcd(IcdEdited._id, values));
    setvisibleEdite(false);
  };
  const columns: any = [
    {
      key: 'ICDCode',
      dataIndex: 'ICDCode',
      title: 'ICDCode',
      width: 100,
      editable: true,
      fixed: 'left',
      render: (name) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: name }}></div>
          <a href={`https://www.google.com/search?q=${name + ' symptoms'}`} target='_blank'>
            {' '}
            <img src='/content/google.png' alt='google' style={{ width: '20px' }} />{' '}
          </a>
        </div>
      )
    },
    {
      key: 'Diagnosis',
      dataIndex: 'Diagnosis',
      title: 'Diagnosis',
      width: 350,
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (name, icd) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: name }}></div>
          <a href={`https://www.google.com/search?q=${name + ' symptoms'}`} target='_blank'>
            {' '}
            <img src='/content/google.png' alt='google' style={{ width: '20px' }} />{' '}
          </a>
          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('Diagnosis', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </div>
      )
    },
    {
      key: 'CodeVersion',
      dataIndex: 'CodeVersion',
      title: 'CodeVersion',
      width: 400,
      editable: true,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>

          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('GeneralSymptoms', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },
    {
      key: 'Specialities',
      dataIndex: 'Specialities',
      title: 'Specialities',
      width: 300,
      ellipsis: true,
      render: (specialities, icd) => (
        <>
          {specialities
            ? specialities.map((tag) => {
                let color = 'geekblue';
                return (
                  <Tag
                    color={color}
                    key={tag}
                    style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {tag.speciality.toUpperCase()}
                  </Tag>
                );
              })
            : '___'}
        </>
      )
    },
    {
      key: 'Countries',
      dataIndex: 'Countries',
      title: 'Countries',
      width: 200,
      ellipsis: true,
      render: (Countries) => (
        <>
          {' '}
          {Countries
            ? Countries.map((tag) => {
                let color = 'geekblue';
                return (
                  <Tag
                    color={color}
                    key={tag}
                    style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {tag.countryCode.toUpperCase()}
                  </Tag>
                );
              })
            : '___'}
        </>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 90,
      className: 'Icd-edite',
      fixed: 'right',
      render: Actions
    }
  ];

  const change = (page) => {};
  const page = parseInt(localStorage.getItem('icdPage'));
  return (
    <>
      <Table
        columns={columns}
        onRow={(icd, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              props.show(icd);
              history.push(`/IcdDetail/${icd._id}`);
            } // click row
          };
        }}
        pagination={{
          defaultCurrent: page,
          total: TotalCommon,
          onChange: change,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        className='accent-header icd-table'
        rowKey='_id'
        bordered
        scroll={{ x: 600, y: 700 }}
        loading={loading}
        dataSource={common}
      />

      <Modal
        visible={visibleEdite}
        onOk={() => form.submit()}
        okText='update'
        onCancel={() => setvisibleEdite(false)}
      >
        <Form form={form} onFinish={handleEdite}>
          <Form.Item name={fieldEdited} initialValue={fieldValue}>
            <JoditEditor value='' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CommonDiagnosisTable;
