import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import * as action from '../../../redux/auth/action'


const SettingsDropdown = (props) => {
  const accountItems = [
    { text: 'Edit account', icon: 'icofont-ui-home', route: '/vertical/edit-account' },
    { text: 'change my password', icon: 'icofont-edit', route: '/changePassword'},
    { text: 'User profile', icon: 'icofont-ui-user', route: '/vertical/user-profile' },
    { text: 'Calendar', icon: 'icofont-ui-calendar', route: '/vertical/events-calendar' },
    { text: 'Settings', icon: 'icofont-ui-settings', route: '/vertical/settings' },
    { text: 'Log Out', icon: 'icofont-logout', route: '/public/sign-in' , action : props.logout},
  ];
  const accountMenu = (
    <Menu style={{ minWidth: '180px' }}>
      {accountItems.map((item, index) => (
        <Menu.Item className='action-item' key={index}>
          <NavLink className='d-flex w-100' to={item.route} replace onClick ={item.action}>
            <span className={`icon mr-3 ${item.icon}`} />
            <span className='text'>{item.text}</span>
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={accountMenu} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar
          size={40}
          className='mr-1'
          src={`${window.location.origin}/content/user-40-2.jpg`}
        />
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    logout : () => dispatch(action.logout())
  }
}
export default connect(null , mapDispatchToProps)(SettingsDropdown);
