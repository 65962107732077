import React, { Component } from 'react';
import { Menu, Button, Badge } from 'antd';
import {
  InboxOutlined,
  FileTextOutlined,
  MailOutlined,
  StarOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const MailMenu = (props) => {
  const { match, location } = props;
  return (
    <div className='w-100'>
      <div className='p-3'>
        <Link to={`${match.url}/compose`}>
          <Button type='primary' block>
            <EditOutlined />
            <span>Compose</span>
          </Button>
        </Link>
      </div>
      <Menu
        defaultSelectedKeys={[`${match.url}/inbox/1`]}
        mode='inline'
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key={`${match.url}/inbox`}>
          <InboxOutlined />
          <span>Inbox</span>
          <Link to={`${match.url}/inbox`} />
        </Menu.Item>
        <Menu.Item key={`${match.url}/starred`}>
          <StarOutlined />
          <span>Starred</span>
          <Link to={`${match.url}/starred`} />
        </Menu.Item>
        <Menu.ItemGroup title='Labels'>
          <Menu.Item key={`${match.url}/Done`}>
            <Badge color='green' />
            <span>Done</span>
            <Link to={`${match.url}/Done`} />
          </Menu.Item>
          <Menu.Item key={`${match.url}/todo`}>
            <Badge color='gray' />
            <span>To Do</span>
            <Link to={`${match.url}/todo`} />
          </Menu.Item>
          <Menu.Item key={`${match.url}/important`}>
            <Badge color='red' />
            <span>Important</span>
            <Link to={`${match.url}/important`} />
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </div>
  );
};

export default MailMenu;
