import React, { useState, useEffect } from 'react';
import { Switch, Table, Button, Modal, Tag, Pagination, Select, Tooltip, Form, Input } from 'antd';
// import AdminForm from './icd11addForm';
import { editIcd, getIcds, getMoreIcds, show } from '../../../redux/icd11/actions';
import { connect, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import JoditEditor from 'jodit-react';
import { useForm } from 'antd/es/form/Form';
import './biosystem.scss';
import Axios from 'axios';

const BiosystemTable = (props) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [biosystems, setBiosystem] = useState([]);
  const [totalBiosystems, setTotalBiosystems] = useState();
  const getbiosystem = (page = 1, limit = 10) => {
    setLoading(true);
    Axios({
      method: 'GET',
      url: '/biosystems',
      params: { page, limit }
    }).then((res) => {
      setBiosystem(res.data.docs);
      setTotalBiosystems(res.data.totalDocs);
      setLoading(false);
    });
  };
  useEffect(() => {
    getbiosystem();
  }, []);
  const Actions = (biosystem) => {
    const handleShow = () => {
      history.push(`/editingBiosystem/true/${biosystem._id}`);
    };
    return (
      <div className='buttons-list nowrap'>
        <Button onClick={handleShow.bind({}, biosystem)} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      </div>
    );
  };
  const [visibleEdite, setvisibleEdite] = useState(false);
  const [fieldEdited, setfieldEdited] = useState();
  const [IcdEdited, setIcdEdited] = useState({ _id: null });
  const [fieldValue, setFeildValue] = useState();
  const showModal = (fieldName, fieldValue, icd) => {
    setfieldEdited(fieldName);
    setIcdEdited(icd);
    setFeildValue(fieldValue);
    setvisibleEdite(true);
  };

  const handleEdite = (values) => {
    dispatch(editIcd(IcdEdited._id, values));
    setvisibleEdite(false);
  };
  const columns: any = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'name',
      width: 250,
      fixed: 'left',
      ellipsis: true,
      render: (name) => name
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
      width: 300,
      ellipsis: true,
      render: (description) => <>{description ? description : '___'}</>
    },
    {
      key: 'organs',
      dataIndex: 'organs',
      title: 'organs',
      width: 320,
      innerHeight: 150,
      ellipsis: true,
      render: (tags) => (
        <>
          {tags
            ? tags.map((tag) => {
                let color = 'geekblue';
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag
                    color={color}
                    key={tag.organ}
                    style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {tag.organ}
                  </Tag>
                );
              })
            : '___'}
        </>
      )
    },
    {
      key: 'icd10',
      dataIndex: 'icd10',
      title: 'icd10',
      width: 350,

      render: (tags) => (
        <>
          {tags
            ? tags.map((tag) => {
                let color = tag.length > 5 ? 'geekblue' : 'geekblue';
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag
                    color={color}
                    key={tag}
                    style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {tag.toUpperCase()}
                  </Tag>
                );
              })
            : '___'}
        </>
      )
    },
    {
      key: 'icd11',
      dataIndex: 'icd11',
      title: 'icd11',
      width: 320,
      editable: true,
      ellipsis: true,
      render: (tags) => (
        <>
          {tags
            ? tags.map((tag) => {
                let color = tag.length > 5 ? 'geekblue' : 'geekblue';
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag
                    color={color}
                    key={tag}
                    style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {tag.toUpperCase()}
                  </Tag>
                );
              })
            : '___'}
        </>
      )
    },

    // {
    //   key: 'specialities',
    //   dataIndex: 'specialities',
    //   title: 'specialities',
    //   width: 350,
    //   render: (specialities) => (
    //     <>
    //       {specialities
    //         ? specialities.map((tag) => {
    //             let color = tag.en.length > 5 ? 'geekblue' : 'geekblue';
    //             return (
    //               <Tag
    //                 color={color}
    //                 key={tag}
    //                 style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
    //               >
    //                 {tag.en}
    //               </Tag>
    //             );
    //           })
    //         : '___'}
    //     </>
    //   )
    // },
    {
      key: 'actions',
      title: 'Actions',
      width: 90,
      className: 'Icd-edite',
      fixed: 'right',
      render: Actions
    }
  ];

  const change = (page) => {
    getbiosystem(page);
  };
  const handlesearchChange = (value) => {
    if (value === '') {
      getbiosystem(page);
    }
  };
  const handleSearch = (value) => {
    console.log({ value });
    if (value === '') {
      getbiosystem(page);
    }
    setLoading(true);
    Axios({
      method: 'GET',
      url: '/biosystems/search',
      params: { page: 1, limit: 10, search: value }
    }).then((res) => {
      setBiosystem(res.data.docs);
      setTotalBiosystems(res.data.totalDocs);
      setLoading(false);
    });
  };
  const page = parseInt(localStorage.getItem('icdPage'));
  return (
    <>
      <div className='biosystem_form'>
        <Input.Search
          placeholder='search here'
          onSearch={handleSearch}
          onChange={(e) => handlesearchChange(e.target.value)}
        />
      </div>
      <Table
        columns={columns}
        onRow={(biosystem, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              history.push(`/editingBiosystem/true/${biosystem._id}`);
            } // click row
          };
        }}
        pagination={{
          defaultCurrent: page,
          total: totalBiosystems,
          onChange: change,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        className='accent-header icd-table'
        rowKey='_id'
        bordered
        scroll={{ x: 600, y: 700 }}
        loading={loading}
        dataSource={biosystems}
      />

      <Modal
        visible={visibleEdite}
        onOk={() => form.submit()}
        okText='update'
        onCancel={() => setvisibleEdite(false)}
      >
        <Form form={form} onFinish={handleEdite}>
          <Form.Item name={fieldEdited} initialValue={fieldValue}>
            <JoditEditor value='' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetIcds: (page) => dispatch(getIcds(page)),
    show: (icd) => dispatch(show(icd))
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.icd11.loading,
    error: state.icd11.error,
    total: state.icd11.total,
    page: state.icd11.page
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BiosystemTable);
