import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';
import IcdsTable from './commonDTable';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';
import UsersForm from './commonaddForm';

import { usePageData } from '../../../hooks/usePage';
import { getIcds, addingIcd, closeModal, addIcd } from '../../../redux/icd11/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

const CommonDiagnosisPage = (props) => {
  const pageData: IPageData = {
    title: `common Diagnosis`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'common Diagnosis'
      }
    ]
  };
  usePageData(pageData);
  const history = useHistory();
  return (
    <>
      <IcdsTable />
      <PageAction onClick={() => history.push('/addCommonDiagnosis')} icon='icofont-contact-add' />
    </>
  );
};

export default CommonDiagnosisPage;
