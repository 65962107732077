import React from "react";
import { Spin } from "antd";

function FullPageLoading() {
  return (
    <div className="full-page-loading">
      <Spin size="large" />
      <p>loading....</p>
    </div>
  );
}

export default FullPageLoading;
