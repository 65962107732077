export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION';
export const SHOW_SUBSCRIPTION = 'SHOW_SUBSCRIPTION';
export const SUCCESSED_EDIT_SUBSCRIPTION = 'SUCCESSED_EDIT_SUBSCRIPTION';
export const SET_REQ = 'SET_REQ'
export const FAIL_SET = 'FAIL_SET'
export const Show_REQ= 'Show_REQ'
export const OPEN_REJECT_MODAL = "OPEN_REJECT_MODAL";
export const CLOSE_REJECT_MODAL = "CLOSE_REJECT_MODAL";
export const OPEN_ACCEPT_MODAL  = "OPEN_ACCEPT_MODAL";
export const CLOSE_ACCEPT_MODAL  = "CLOSE_ACCEPT_MODAL";
