import React, { useState , useEffect} from 'react';
import { Switch, Table, Button, Modal, Tag, Pagination, Select } from 'antd';
import AdminForm from './usersForm';
import {getUsers , switchUser , show} from '../../../redux/users/action'
import { connect } from 'react-redux';
import { useHistory } from 'react-router';


const UsersTable = (props) => {
  const [user , setUser] = useState(null)
  const [visibility, setVisibility] = useState(false);
  const closeModal = () => setVisibility(false);
  
  const Option = Select.Option;
  const history = useHistory()
  

  const Actions = (user) =>{ 

    const handleShow = () => {
      props.show(user)
      history.push('/vertical/user')
    }
    return(
    <div className='buttons-list nowrap'>
      <Button  onClick ={handleShow.bind({} , user)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
    </div>
  )};
  const Statu = (user) =>{ 
    const [statu , setStatu] = useState(user.isActive)
    const handleChange =()=>{
      props.onswitch(user._id , !user.isActive)
      setStatu(!statu)
    }
    return(
    <div className='buttons-list nowrap'>
      <Switch checked ={statu} onChange = {handleChange} style ={{margin : '15px'}}/>
    </div>
  )};

  const columns = [
    {
      key: 'username',
      dataIndex: 'username',
      title: 'username',
      sorter: (a, b) => (a.username > b.username ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'email',
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'id',
      dataIndex: '_id',
      title: 'id',
      sorter: (a, b) => (a.phoneNumber > b.phoneNumber ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      title: 'phoneNumber',
      sorter: (a, b) => (a.phoneNumber > b.phoneNumber ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'actions',
      title: 'Actions',
      render: Actions
    },
    {
      key: 'statu',
      title: 'statu',
      render: Statu
    },
  ];
  const [role , setRole] = useState(props.role)
  const roles = ["clinic", "medical_center", "pharmacy", "laboratory_center", "radiology_center", "pharma_company", "agent", "patient", "super_admin", "admin"]
  const change = page => {
    props.onGetusers(page , role);
  }
  const onChangeRole = (value) => {
    props.onGetusers(1 , value)
    setRole(value)
  }
  return (
    <>
      <Select defaultValue = {role} style ={{width : '300px' , margin : '30px 0'}} onChange = {onChangeRole}>
      {roles.map(item => {
        return <Option value = {item} > {item} </Option>
      })}
    </Select>
      <Table
        pagination={{defaultCurrent : 1 ,total : props.total ,onChange : change}}
        className='accent-header'
        rowKey='_id'
        loading ={props.loading}
        dataSource={props.users}
        columns={columns}
      />

      <Modal
        visible={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>update Admin</h3>}
      >
        <AdminForm
          submitText='Update Admin'
          onCancel={closeModal}
          onSubmit={props.onEditUser}
          user={user}
        />
      </Modal>
    </>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    onGetusers : (page, role) => dispatch(getUsers(page, role)),
    onswitch : (id , isActive) => dispatch(switchUser(id , isActive)),
    show : (user) => dispatch(show(user))
  }
}
const mapStateToProps = state => {
  return{
    loading :state.users.loading,
    error : state.users.error,
    total : state.users.total,
    role : state.users.role
  }
}
export default connect(mapStateToProps , mapDispatchToProps)(UsersTable);
