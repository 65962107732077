import React, { useState, useEffect } from 'react';
import { Switch, Table, Button, Modal, Tag, Pagination, Select, Tooltip } from 'antd';
import { useHistory } from 'react-router';

const FormulaTable = (props) => {
  const history = useHistory();
  const Actions = (formula) => {
    const handleShow = () => {
      history.push(`/formulaDetail/${formula._id}`);
    };
    const handlePreview = () => {
      history.push(`/PreviwFormula/${formula._id}`);
    };

    return (
      <div className='buttons-list nowrap'>
        <Button onClick={handleShow.bind({}, formula)} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
        <Button onClick={handlePreview.bind({}, formula)} shape='circle' type='primary'>
          <span className='icofont icofont-external-link' />
        </Button>
      </div>
    );
  };

  const columns: any = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'title',
      width: 100,
      render: (name) => (
        <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
      )
    },
    {
      key: 'formula',
      dataIndex: 'formula',
      title: 'formula',
      width: 100,
      render: (name) => (
        <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
      )
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
      width: 100,
      render: (name) => (
        <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 90,
      className: 'Icd-edite',
      fixed: 'right',
      render: Actions
    }
  ];

  const change = (page) => {};
  const page = parseInt(localStorage.getItem('icdPage'));
  return (
    <>
      <Table
        columns={columns}
        onRow={(formula, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              // props.show(icd);
              history.push(`/formulaDetail/${formula._id}`);
            } // click row
          };
        }}
        pagination={{
          defaultCurrent: page,
          total: props.total,
          onChange: change,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        className='accent-header icd-table'
        rowKey='_id'
        bordered
        scroll={{ x: 600, y: 700 }}
        loading={props.loading}
        dataSource={props.formuls}
      />
    </>
  );
};

export default FormulaTable;
