import React, { useState, useEffect } from 'react';
import {
  Switch,
  Table,
  Button,
  Modal,
  Tag,
  Pagination,
  Select,
  Form,
  Col,
  Row,
  Checkbox
} from 'antd';
import AdminForm from './udeUserForm';
import { getUsers, switchUser, show } from '../../../redux/udeUser/action';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Specialtyinput from '../../components/specialtyInput/specialtyinput';
import { useForm } from 'antd/es/form/Form';

import './udeUsersTable.scss';
import Axios from 'axios';
const UdeUsersTable = (props) => {
  const [user, setUser] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const closeModal = () => setVisibility(false);

  const Option = Select.Option;
  const history = useHistory();

  const Actions = (user) => {
    const handleShow = () => {
      props.show(user);
      history.push(`/udeUserDetails/${user._id}`);
    };
    return (
      <div className='buttons-list nowrap'>
        <Button onClick={handleShow.bind({}, user)} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      </div>
    );
  };
  const Statu = (user) => {
    const [statu, setStatu] = useState(user.isActive);
    const handleChange = () => {
      props.onswitch(user._id, !user.isActive);
      setStatu(!statu);
    };
    return (
      <div className='buttons-list nowrap'>
        <Switch checked={statu} onChange={handleChange} style={{ margin: '15px' }} />
      </div>
    );
  };

  const columns = [
    {
      key: 'username',
      dataIndex: 'username',
      title: 'username',
      width: '300px',
      sorter: (a, b) => (a.username > b.username ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'speciality',
      dataIndex: 'speciality',
      title: 'speciality',
      render: (name) => <strong>{name ? name.mainSpeciality.en : 'patient'}</strong>
    },
    {
      key: 'phoneNumber',
      dataIndex: 'phone',
      title: 'phoneNumber',
      sorter: (a, b) => (a.phone > b.phone ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'actions',
      title: 'Actions',
      render: Actions
    },
    {
      key: 'statu',
      title: 'statu',
      render: Statu
    }
  ];

  const change = (page) => {
    props.onGetusers(page);
  };

  const [form] = useForm();

  const handlefinish = (values) => {
    const countries = values.country === 'All' ? {} : { countryCode: values.country };
    const specialities =
      values.speciality === 'all' || values.speciality === 'patient'
        ? {}
        : { specialityId: values.speciality };
    const type = values.type === 'all' ? {} : { type: values.type };
    props.onGetusers(1, { ...countries, ...specialities, ...type });
  };
  const handleReset = () => {
    form.resetFields();
    form.submit();
  };
  const [countries, setCountries] = useState([
    { flagPath: '', countryCode: '', phoneCode: '', code: '', name: '' }
  ]);

  useEffect(() => {
    Axios({
      method: 'GET',
      url: 'u-consultation/country',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((responsse) => {
      setCountries(responsse.data.docs);
    });
  }, []);
  const countryOption = countries.map((item) => {
    return (
      <Option value={item.code} key={item.countryCode}>
        {item.name}
      </Option>
    );
  });
  const [type, setType] = useState(true);
  const handleTypeChange = (value) => {
    value === 'Doctor' ? setType(false) : setType(true);
  };
  return (
    <>
      <div className='filterUde'>
        <Form
          form={form}
          layout='vertical'
          onFinish={handlefinish}
          onReset={handleReset}
          initialValues={{ country: 'All', speciality: 'all', type: 'all' }}
        >
          <Form.Item name='type' label='type'>
            <Select onChange={handleTypeChange}>
              <Option value='Doctor'>Doctor</Option>
              <Option value='patient'>patient</Option>
              <Option value='all'>All</Option>
            </Select>
          </Form.Item>
          <Specialtyinput disabled={type} />
          <Form.Item name='country' label='country'>
            <Select
              className='contury'
              defaultValue='all'
              dropdownClassName='countryDropdwon'
              showSearch
              placeholder='please select your main'
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countryOption}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginTop: '60px', width: '70px' }}>
            <Button type='primary' htmlType='submit'>
              apply filter
            </Button>
          </Form.Item>
          <Form.Item style={{ marginTop: '60px', width: '50px' }}>
            <Button type='default' htmlType='reset'>
              clear filter
            </Button>
          </Form.Item>
        </Form>
      </div>

      <Table
        pagination={{ defaultCurrent: 1, total: props.total, onChange: change }}
        className='accent-header'
        rowKey='_id'
        loading={props.loading}
        dataSource={props.users}
        columns={columns}
      />

      <Modal
        visible={visibility}
        footer={null}
        onCancel={closeModal}
        title={<h3 className='title'>update Admin</h3>}
      >
        <AdminForm
          submitText='Update Admin'
          onCancel={closeModal}
          onSubmit={props.onEditUser}
          user={user}
        />
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetusers: (page, filters) => dispatch(getUsers(page, filters)),
    onswitch: (id, isActive) => dispatch(switchUser(id, isActive)),
    show: (user) => dispatch(show(user))
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.udeUser.loading,
    error: state.udeUser.error,
    total: state.udeUser.total,
    role: state.udeUser.role
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UdeUsersTable);
