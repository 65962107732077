import React, {useState , useEffect} from 'react';

import { Modal } from 'antd';
import { useDispatch , connect} from 'react-redux';

import AdminsTable from './adminTable';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';
import AdminForm from './adminForm';

import {usePageData} from '../../../hooks/usePage';
import { AddAdmin , getAdmins , openModal , closeModal} from '../../../redux/admins/action';


const pageData: IPageData = {
  title: 'Admins',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Medicine',
      route: 'default-dashboard'
    },
    {
      title: 'Admins'
    }
  ]
};
const AdminsPage = ({admins , addingAdmin , closeModal , openModal}) => {
  usePageData(pageData);
  const dispatch = useDispatch();
useEffect(() =>{
  dispatch(getAdmins(1))
},[])
  const onAddAdmin = (admin) => dispatch(AddAdmin(admin))
  // const onEditAdmin = (admine) => editAdmin(admine);
  return (
    <>
      <AdminsTable
        // onDeleteAdmin={deleteAdmin}
        // onEditAdmin={onEditAdmin}
        admins={admins}
      />
      <PageAction onClick={() => openModal()} icon='icofont-contact-add' />

      <Modal
        visible={addingAdmin}
        onCancel={closeModal}
        title={<h3 className='title'>Add Admin</h3>}
        destroyOnClose
        footer={null}
      >
        <AdminForm onSubmit={onAddAdmin} onCancel={() => closeModal()} submitText ="Add Admin" />
      </Modal>
    </>
  );
};
const mapStateToProps = (state) =>{
  return{
    admins : state.admins.admins,
    addingAdmin :state.admins.showAdding
  }
}
const mapDisptchToProps = dispatch => {
  return{
    openModal : () => dispatch(openModal()),
    closeModal : () => dispatch(closeModal())
  }
}
export default connect(mapStateToProps ,mapDisptchToProps)(AdminsPage);
