import {SET_PROFILES ,SHOW_PROFILE, OPEN_ACCEPT_MODAL , CLOSE_Accept_MODAL , OPEN_Rejected_MODAL , CLOSE_Rejected_MODAL} from './types'

const initState = {
    profiles  : [],
    profile : [],
    total : null,
    accepted : false,
    rejected : false,
    loading : true,
    message : ''
}
export const profilesReducer = (state = initState , action) => {
    switch (action.type) {
        case SET_PROFILES:
            return{
                ...state,
                profiles : action.profiles,
                total  : action.total,
                loading : false
            }
        case SHOW_PROFILE :
            return{
                ...state,
                profile : action.profile
            }
        case OPEN_ACCEPT_MODAL : 
            return {
                ...state,
                accepted :true
            }
        case CLOSE_Accept_MODAL : 
            return {
                ...state,
                accepted :false
            }
        case OPEN_Rejected_MODAL : 
            return {
                ...state,
                rejected :true
            }
        case CLOSE_Rejected_MODAL : 
            return {
                ...state,
                rejected :false
            }
        default:
            return state
    }
}