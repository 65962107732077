import React, { useState, useEffect } from 'react';
import { Table, Avatar, Badge, Tooltip, Dropdown, Menu, Input } from 'antd';
import { StarOutlined, StarFilled, DeleteOutlined, TagOutlined } from '@ant-design/icons';
import MailData from '../../../assets/data/mail.data.json';
import { labels, getLabelColor } from './MailLabels';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
const mail = {
  label: null,
  starred: [],
  replies: [],
  _id: null,
  title: 'mr',
  specialty: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  message: null,
  country: null,
  createdAt: null
};
const MailItem = (props) => {
  const [mails, setMails] = useState([mail]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    console.log(selectedRowKeys);
  };

  const onStarTicked = (id) => {
    Axios({
      method: 'POST',
      url: `contact-us/star/${id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(() => {
      getMails();
    });
  };

  //   const formatBody = (body) => {
  //     return body.replace(/<(?:.|\n)*?>/gm, ' ');
  //   };
  const [user, setUser] = useState({ _id: null });
  const getUser = () =>
    Axios({
      method: 'GET',
      url: 'u-consultation/auth/me',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      setUser(res.data.data);
    });
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);
  const getMails = (filters?: object, page = 1) => {
    setLoading(true);
    Axios({
      method: 'GET',
      url: '/contact-us/messages',
      params: { limit: 10, page: page, ...filters },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      setMails(res.data.docs);
      setPage(res.data.page);
      setTotalPage(res.data.totalDocs);
      setLoading(false);
    });
    return mails;
  };
  const change = (page) => {
    getMails(filetres, page);
  };
  const [filetres, setFilters] = useState({});
  const params: any = useParams();
  const getCurrentCategory = () => {
    const { category } = params;
    if (labels.includes(category)) {
      setFilters({ label: category });
      return getMails({ label: category });
    }
    switch (category) {
      case 'inbox':
        setFilters({});
        return getMails();
      case 'starred':
        setFilters({ starred: true });
        return getMails({ starred: true });
      default:
        break;
    }
  };
  const loadMail = () => {
    const data = getCurrentCategory();
    setMails(data);
    setSelectedRowKeys([]);
  };
  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    loadMail();
    getUser();
  }, [params]);

  const massDeleted = (selectedKey) => {
    selectedKey.forEach((num) => {
      Axios({
        method: 'DELETE',
        url: `/contact-us/messages/${num}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }).then(() => {
        getMails();
      });
    });
    setSelectedRowKeys([]);
  };

  const massStar = (selectedKey) => {
    selectedKey.forEach((num) => {
      onStarTicked(num);
    });
    setSelectedRowKeys([]);
  };

  const massCategorize = (label, selectedKey) => {
    selectedKey.forEach((num) => {
      Axios({
        method: 'POST',
        url: `/contact-us/addLabel/${num}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        data: { label }
      }).then(() => {
        getMails();
      });
    });
    setSelectedRowKeys([]);
  };

  const search = (e) => {
    e.target.value ? getMails({ firstName: e.target.value }) : getMails();
  };

  const { match, history } = props;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const locale = {
    emptyText: (
      <div className='text-center my-5'>
        <img src='/img/others/img-10.png' alt='Add credit card' />
        <h3 className='mt-3 font-weight-light'>There is no mail!</h3>
      </div>
    )
  };
  const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;
  const tableColumns = [
    {
      title: () => (
        <div className='mail-list-action'>
          <div>
            {hasSelected ? (
              <div>
                <Dropdown
                  overlay={
                    <Menu>
                      {labels.map((elm) => (
                        <Menu.Item
                          key={`key-${elm}`}
                          onClick={() => {
                            massCategorize(elm, selectedRowKeys);
                          }}
                        >
                          <Badge color={getLabelColor(elm)} />
                          <span className='text-capitalize'>{elm}</span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <span className='mail-list-action-icon ml-0' onClick={(e) => e.preventDefault()}>
                    <TagOutlined />
                  </span>
                </Dropdown>
                <span
                  className='mail-list-action-icon'
                  onClick={() => {
                    massDeleted(selectedRowKeys);
                  }}
                >
                  <Tooltip title='Delete'>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
                <span
                  className='mail-list-action-icon'
                  onClick={() => {
                    massStar(selectedRowKeys);
                  }}
                >
                  <Tooltip title='Star'>
                    <StarOutlined />
                  </Tooltip>
                </span>
              </div>
            ) : null}
          </div>
          <div>
            <Input
              size='small'
              placeholder='Search'
              onChange={(e) => {
                search(e);
              }}
            />
          </div>
        </div>
      ),
      colSpan: 12,
      width: 300,
      dataIndex: 'name',
      className: 'mail-list-sender',
      render: (_, elm) => (
        <div className='d-flex align-items-center'>
          <div
            onClick={(e) => {
              e.stopPropagation();
              onStarTicked(elm._id);
            }}
            className={`mail-list-star font-size-md ${elm.starred ? 'checked' : 'uncheck'}`}
          >
            {elm.starred.includes(user._id) ? <StarFilled /> : <StarOutlined />}
          </div>
          <div className='d-flex align-items-center'>
            {/* <Avatar src={elm.avatar} size={30} /> */}
            <h4 className='ml-2'>{elm.title + ' - ' + elm.firstName + ' ' + elm.lastName}</h4>
          </div>
        </div>
      )
    },
    {
      title: '',
      colSpan: 0,
      className: 'mail-list-content',
      render: (_, elm) => (
        <div className=' mail-list-content-msg'>
          <Badge color={getLabelColor(elm.label)} />
          {/* <span className='font-weight-semibold text-dark ml-1'>{elm.title}</span>
          <span className='mx-2'> - </span> */}
          <span className='p mb-0' style={{ textAlign: 'center' }}>
            {elm.message}
          </span>
        </div>
      )
    }
  ];

  return (
    <div className='mail-list'>
      <Table
        rowSelection={rowSelection}
        columns={tableColumns}
        dataSource={mails}
        locale={locale}
        pagination={{
          defaultPageSize: 10,
          defaultCurrent: page,
          total: totalpage,
          onChange: change,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        loading={loading}
        // scroll={{ y: 600 }}
        onRow={(elm: any) => {
          return {
            onClick: (e) => {
              e.preventDefault();
              history.push(`${match.url}/${elm._id}`);
            }
          };
        }}
        rowKey='_id'
      />
    </div>
  );
};

export default MailItem;
