import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { connectRouter } from 'connected-react-router';
import { createHashHistory } from 'history';

import { settingsReducer } from './settings/reducer';
import { pageDataReducer } from './page-data/reducer';
import { patientsReducer } from './patients/reducer';
import { logReducer } from './auth/reducer';
import { adminsReducer } from './admins/reducer';
import { userReducer } from './users/reducer';
import { udeUserReducer } from './udeUser/reducer';
import { profilesReducer } from './profiles/reducers';
import { subscriptionReducer } from './subscription/reducer';
import { reducer } from './general/reducer';
import { icdReducer } from './icd/reducer';
import { icd11Reducer } from './icd11/reducer';
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

const rootReducer = combineReducers({
  pageData: pageDataReducer,
  settings: settingsReducer,
  patients: patientsReducer,
  auth: logReducer,
  admins: adminsReducer,
  users: userReducer,
  profiles: profilesReducer,
  subscription: subscriptionReducer,
  general: reducer,
  icd: icdReducer,
  icd11: icd11Reducer,
  udeUser: udeUserReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
