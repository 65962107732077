import React, { useState, useEffect } from 'react';
import { Switch, Table, Button, Modal, Tag, Pagination, Select, Tooltip, Form } from 'antd';
import AdminForm from './addForm';
import { editIcd, getIcds, getMoreIcds, show } from '../../../redux/icd/actions';
import { connect, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import JoditEditor from 'jodit-react';
import { useForm } from 'antd/es/form/Form';

const IcdsTable = (props) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useDispatch();
  const Actions = (icd) => {
    const handleShow = () => {
      props.show(icd);
      history.push(`/IcdDetail/${icd._id}`);
    };
    return (
      <div className='buttons-list nowrap'>
        <Button onClick={handleShow.bind({}, icd)} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      </div>
    );
  };
  const [visibleEdite, setvisibleEdite] = useState(false);
  const [fieldEdited, setfieldEdited] = useState();
  const [IcdEdited, setIcdEdited] = useState({ _id: null });
  const [fieldValue, setFeildValue] = useState();
  const showModal = (fieldName, fieldValue, icd) => {
    setfieldEdited(fieldName);
    setIcdEdited(icd);
    setFeildValue(fieldValue);
    form.setFieldsValue({ [fieldName]: fieldValue });
    setvisibleEdite(true);
  };

  const handleCancel = () => {
    setvisibleEdite(false);
    setfieldEdited(null);
    setIcdEdited({ _id: null });
    setFeildValue(null);
  };
  const handleEdite = (values) => {
    dispatch(editIcd(IcdEdited._id, values));
    setvisibleEdite(false);
    setfieldEdited(null);
    setIcdEdited({ _id: null });
    setFeildValue(null);
  };
  const columns: any = [
    {
      key: 'ChildCode',
      dataIndex: 'ChildCode',
      title: 'Child Code',
      width: 100,
      editable: true,
      fixed: 'left',
      render: (name) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: name }}></div>
          <a href={`https://www.google.com/search?q=${name + ' symptoms'}`} target='_blank'>
            {' '}
            <img src='/content/google.png' alt='google' style={{ width: '20px' }} />{' '}
          </a>
        </div>
      )
    },
    {
      key: 'Diagnosis',
      dataIndex: 'Diagnosis',
      title: 'Diagnosis',
      width: 350,
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (name, icd) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: name }}></div>
          <a href={`https://www.google.com/search?q=${name + ' symptoms'}`} target='_blank'>
            {' '}
            <img src='/content/google.png' alt='google' style={{ width: '20px' }} />{' '}
          </a>
          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('Diagnosis', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </div>
      )
    },
    {
      key: 'GeneralSymptoms',
      dataIndex: 'GeneralSymptoms',
      title: 'GeneralSymptoms',
      width: 400,
      editable: true,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>

          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('GeneralSymptoms', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },
    {
      key: 'SpecificSymptoms',
      dataIndex: 'SpecificSymptoms',
      title: 'SpecificSymptoms',
      width: 400,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('SpecificSymptoms', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },
    {
      key: 'RiskIndex',
      dataIndex: 'RiskIndex',
      title: 'RiskIndex',
      width: 90,
      ellipsis: true,
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'ListOfReferences',
      dataIndex: 'ListOfReferences',
      title: 'ListOfReferences',
      width: 300,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('ListOfReferences', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },
    {
      key: 'System',
      dataIndex: 'System',
      title: 'System',
      width: 150,
      sorter: (a, b) => (a.System > b.System ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'ChapterCode',
      dataIndex: 'ChapterCode',
      title: 'ChapterCode',
      width: 400,

      sorter: (a, b) => (a.ChapterCode > b.ChapterCode ? 1 : -1),
      render: (name) => (
        <>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: name }}></div>{' '}
        </>
      )
    },
    {
      key: 'ParentCode',
      dataIndex: 'ParentCode',
      title: 'ParentCode',
      width: 80,
      sorter: (a, b) => (a.ParentCode > b.ParentCode ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'LaboratoryInvestigations',
      dataIndex: 'LaboratoryInvestigations',
      title: 'LaboratoryInvestigations',
      width: 400,
      sorter: (a, b) => (a.LaboratoryInvestigations > b.LaboratoryInvestigations ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'WhenToSeeDoctor',
      dataIndex: 'WhenToSeeDoctor',
      title: 'WhenToSeeDoctor',
      width: 400,
      sorter: (a, b) => (a.WhenToSeeDoctor > b.WhenToSeeDoctor ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'RadiologyScans',
      dataIndex: 'RadiologyScans',
      title: 'RadiologyScans',
      width: 400,
      sorter: (a, b) => (a.RadiologyScans > b.RadiologyScans ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'PlanOfCare',
      dataIndex: 'PlanOfCare',
      title: 'PlanOfCare',
      width: 400,
      sorter: (a, b) => (a.PlanOfCare > b.PlanOfCare ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'RecommendedMedications',
      dataIndex: 'RecommendedMedications',
      title: 'RecommendedMedications',
      width: 400,
      sorter: (a, b) => (a.RecommendedMedications > b.RecommendedMedications ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'RecommendedTherapy',
      dataIndex: 'RecommendedTherapy',
      title: 'RecommendedTherapy',
      width: 400,
      sorter: (a, b) => (a.RecommendedTherapy > b.RecommendedTherapy ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'MedicalAndNursingRecommendations',
      dataIndex: 'MedicalAndNursingRecommendations',
      title: 'MedicalAndNursingRecommendations',
      width: 400,
      sorter: (a, b) =>
        a.MedicalAndNursingRecommendations > b.MedicalAndNursingRecommendations ? 1 : -1,
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'PrecautionsAndPrevention',
      dataIndex: 'PrecautionsAndPrevention',
      title: 'PrecautionsAndPrevention',
      width: 400,
      sorter: (a, b) => (a.PrecautionsAndPrevention > b.PrecautionsAndPrevention ? 1 : -1),
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 90,
      className: 'Icd-edite',
      fixed: 'right',
      render: Actions
    }
  ];

  const change = (page) => {
    props.onGetIcds(page);
  };
  const page = parseInt(localStorage.getItem('icdPage'));
  return (
    <>
      <Table
        columns={columns}
        onRow={(icd, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              props.show(icd);
              history.push(`/IcdDetail/${icd._id}`);
            } // click row
          };
        }}
        pagination={{
          defaultCurrent: page,
          total: props.total,
          onChange: change,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        className='accent-header icd-table'
        rowKey='_id'
        bordered
        scroll={{ x: 600, y: 700 }}
        loading={props.loading}
        dataSource={props.icds}
      />

      <Modal
        visible={visibleEdite}
        onOk={() => form.submit()}
        okText='update'
        onCancel={() => handleCancel()}
      >
        <Form form={form} onFinish={handleEdite}>
          <Form.Item name={fieldEdited} initialValue={fieldValue}>
            <JoditEditor value='' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetIcds: (page) => dispatch(getIcds(page)),
    show: (icd) => dispatch(show(icd))
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.icd.loading,
    error: state.icd.error,
    total: state.icd.total,
    page: state.icd.page
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IcdsTable);
