import { Button, Input } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Goback from '../../../layout/components/goBack/goback';
const { Search } = Input;
const FormController = (props) => {
  return (
    <div
      className='d-flex justify-content-between buttons-list settings-actions'
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <Goback text='goback' />

      <Button className='updateButton' onClick={() => props.form.submit()}>
        update Icd - <span> {props.childCode} </span>
      </Button>
      <Link to={`${props.id}?modal=open`} className='fetchButton'>
        Icd10
      </Link>
      <Search
        className='icd-search'
        enterButton
        onSearch={props.handleSearch}
        style={{ width: '200px' }}
        placeholder='write child code'
      />
      <Button className='deleteButton' danger onClick={props.handleDeleteIcd}>
        Delete Icd - <span> {props.childCode} </span>
      </Button>
    </div>
  );
};

export default FormController;
