import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Space } from 'antd';
import React from 'react';

const ChoiceForm = (props) => {
  return (
    <>
      <Form.List name={[props.fieldKey, 'choices']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} justify='space-between'>
                <Form.Item
                  {...field}
                  label='name'
                  name={[field.name, 'name']}
                  fieldKey={[field.fieldKey, 'name']}
                  rules={[{ required: true, message: 'Missing name' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label='value'
                  name={[field.name, 'value']}
                  fieldKey={[field.fieldKey, 'value']}
                  rules={[{ required: true, message: 'Missing value' }]}
                >
                  <Input />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button type='default' onClick={() => add()} block icon={<PlusOutlined />}>
                Add choice
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default ChoiceForm;
