import {SET_SUBSCRIPTION ,
     SHOW_SUBSCRIPTION ,
      Show_REQ ,
       SET_REQ ,
        OPEN_REJECT_MODAL 
    ,CLOSE_REJECT_MODAL,
    OPEN_ACCEPT_MODAL,
    CLOSE_ACCEPT_MODAL
} from './types'

const initState = {
    subscription : [],
    editing : false,
    plan : [],
    requsets : [],
    req : [],
    loading : true,
    OpenRejectModal : false,
    openAcceptModal : false
}

export  const subscriptionReducer  = (state  = initState, action) => {
    switch (action.type) {
        case SET_SUBSCRIPTION:
            return{
                ...state,
                subscription : action.subscription
            } 
        case SHOW_SUBSCRIPTION:
            return{
                ...state,
                plan : action.plan
            }
        case SET_REQ:
            return {...state ,
                   requsets: action.requsets,
                   loading : false,
                }
        
        case Show_REQ :
            return {
                    ...state,
                    req: action.req
                }
        case OPEN_REJECT_MODAL :
            return {
                    ...state,
                     OpenRejectModal : true
                }
        case CLOSE_REJECT_MODAL :
            return {
                   ...state,
                  OpenRejectModal : false
                }
        case OPEN_ACCEPT_MODAL:
                    return {
                            ...state,
                             openAcceptModal : true
                        }
        case CLOSE_ACCEPT_MODAL :
             return {
                           ...state,
                          openAcceptModal : false
                        }
        default:
            return state
    }
}