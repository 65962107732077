import React from 'react';

import { Card } from 'antd';

import { useFetchPageData, usePageData } from '../../../hooks/usePage';

import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import AnalyticDashboard from './analytic';

const pageData: IPageData = {
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Dashboards',
      route: 'default-dashboard'
    },
    {
      title: 'Default'
    }
  ]
};

const DashboardPage = () => {
  usePageData(pageData);

  return (
    <>
      <AnalyticDashboard />
    </>
  );
};

export default DashboardPage;
