import * as actionTypes from './types';
import {updateObject} from '../utility';
const initState = {
    token : null,
    userId : null,
    error : null,
    loading : false,
    authRedirectPath : '/',
    forgetPass : false
};

const authStart = (state, action) => {
    return updateObject(state , {error : null , loading : true})
};


const authSuccess = (state, action) => {
    return updateObject(state , {
        token: action.idToken,
        userId : action.userId,
        error : null , 
        loading : false
    })
};
const authFail = (state, action) => {
    return updateObject(state , {
        error : action.error , 
        loading : false
    })
};

const authLogout = (state , action) => {
    return updateObject(state , {
        token : null,
        userId : null,
        forgetPass: false
    });
};
const setauthRedirect = (state , action) => {
    return updateObject(state , {
        authRedirectPath : action.path
    })
}
const  forgetPass =(state ,action) => {
    return updateObject(state , {
        forgetPass: true
    })
}
export const logReducer = (state = initState , action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state , action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state , action);
        case actionTypes.AUTH_FAIL: return authFail(state , action);
        case actionTypes.AUTH_LOGOUT : return authLogout(state , action);
        case actionTypes.AUTH_REDIRECT : return setauthRedirect(state , action);
        case actionTypes.FORGET_PASSWORD : return forgetPass(state , action);
        default:
            return state

    }
}
