import React, { useState, useEffect } from 'react';
import { Switch, Table, Button, Modal, Tag, Pagination, Select, Tooltip, Form } from 'antd';
import AdminForm from './icd11addForm';
import { editIcd, getIcds, getMoreIcds, show } from '../../../redux/icd11/actions';
import { connect, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import JoditEditor from 'jodit-react';
import { useForm } from 'antd/es/form/Form';
import Icdsearch from './Icdsearch';
import FetchfromIcdBottom from '../../components/fetchfromIcd10/fetchfromIcdBottom';
import Axios from 'axios';

const IcdsTable = (props) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useDispatch();
  const Actions = (icd) => {
    const handleShow = () => {
      props.show(icd);
      history.push(`/Icd11Detail/${icd._id}`);
    };
    return (
      <div className='buttons-list nowrap'>
        <Button onClick={handleShow.bind({}, icd)} shape='circle' type='primary'>
          <span className='icofont icofont-edit-alt' />
        </Button>
      </div>
    );
  };
  const [visibleEdite, setvisibleEdite] = useState(false);
  const [fieldEdited, setfieldEdited] = useState();
  const [IcdEdited, setIcdEdited] = useState({ _id: null });
  const [fieldValue, setFeildValue] = useState();
  const [codeFetched, setCodeFetched] = useState([]);
  const showModal = (fieldName, fieldValue, icd) => {
    fieldName === 'Icd10Code' &&
      Axios({
        method: 'GET',
        url: 'u-consultation/icd',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { ChildCode: fieldValue }
      }).then((res) => {
        setCodeFetched(res.data.docs);
      });
    setfieldEdited(fieldName);
    setFeildValue(fieldValue);
    form.setFieldsValue({ [fieldName]: fieldValue });
    setIcdEdited(icd);
    setvisibleEdite(true);
  };
  const handleSelect = (data) => {
    setCodeFetched(data);
  };
  const handleEdite = (values) => {
    dispatch(editIcd(IcdEdited._id, values));
    setvisibleEdite(false);
  };
  const handleFinshFetch = (form, icd) => {
    console.log(form.getFieldsValue());
    dispatch(editIcd(icd._id, form.getFieldsValue()));
  };
  const columns: any = [
    {
      key: 'ChildCode',
      dataIndex: 'ChildCode',
      title: 'Child Code',
      width: 100,
      editable: true,
      fixed: 'left',
      render: (name) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: name }}></div>
          <a href={`https://www.google.com/search?q=${name + ' symptoms'}`} target='_blank'>
            {' '}
            <img src='/content/google.png' alt='google' style={{ width: '20px' }} />{' '}
          </a>
        </div>
      )
    },
    {
      key: 'Diagnosis',
      dataIndex: 'Diagnosis',
      title: 'Diagnosis',
      width: 350,
      sorter: (a, b) => (a.fullName > b.fullName ? 1 : -1),
      render: (name, icd) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div dangerouslySetInnerHTML={{ __html: name }}></div>

          <FetchfromIcdBottom
            mode='diagnosis'
            diagnosis={name}
            currentCodes={icd.Icd10Codes}
            handleFinish={(form) => handleFinshFetch(form, icd)}
          />
          {/* <FetchfromIcdBottom mode='diagnosis' diagnosis={name} /> */}
          {/* <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('Diagnosis', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button> */}
        </div>
      )
    },
    {
      key: 'GeneralSymptoms',
      dataIndex: 'GeneralSymptoms',
      title: 'GeneralSymptoms',
      width: 400,
      editable: true,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>

          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('GeneralSymptoms', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },
    {
      key: 'SpecificSymptoms',
      dataIndex: 'SpecificSymptoms',
      title: 'SpecificSymptoms',
      width: 400,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('SpecificSymptoms', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },
    {
      key: 'Icd10Codes',
      dataIndex: 'Icd10Codes',
      title: 'Icd10Codes',
      width: 150,
      ellipsis: true,
      render: (name, icd) => (
        <>
          {name
            ? name.map((tag) => {
                let color = tag.length > 5 ? 'geekblue' : 'geekblue';
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag
                    color={color}
                    key={tag}
                    style={{ maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {tag.toUpperCase()}
                  </Tag>
                );
              })
            : '___'}
          {/* <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('Icd10Code', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button> */}
        </>
      )
    },
    {
      key: 'RiskIndex',
      dataIndex: 'RiskIndex',
      title: 'RiskIndex',
      width: 90,
      ellipsis: true,
      render: (name) => <div dangerouslySetInnerHTML={{ __html: name }}></div>
    },
    {
      key: 'ListOfReferences',
      dataIndex: 'ListOfReferences',
      title: 'ListOfReferences',
      width: 300,
      ellipsis: true,
      render: (name, icd) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: name }} className='Icd-table-colume'></div>
          <Button
            shape='circle'
            type='primary'
            className='edit_one_cell'
            onClick={() => showModal('ListOfReferences', name, icd)}
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        </>
      )
    },

    {
      key: 'actions',
      title: 'Actions',
      width: 90,
      className: 'Icd-edite',
      fixed: 'right',
      render: Actions
    }
  ];

  const change = (page) => {
    props.onGetIcds(page);
  };
  const page = parseInt(localStorage.getItem('icdPage'));
  const handleCodeFetching = (form) => {
    dispatch(
      editIcd(IcdEdited._id, {
        ...form.getFieldsValue()
      })
    );
  };
  return (
    <>
      <Table
        columns={columns}
        onRow={(icd, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              props.show(icd);
              history.push(`/Icd11Detail/${icd._id}`);
            } // click row
          };
        }}
        pagination={{
          defaultCurrent: page,
          total: props.total,
          onChange: change,
          showQuickJumper: true,
          showSizeChanger: false
        }}
        className='accent-header icd-table'
        rowKey='_id'
        bordered
        scroll={{ x: 600, y: 700 }}
        loading={props.loading}
        dataSource={props.icds}
      />

      <Modal
        visible={visibleEdite}
        onOk={() => form.submit()}
        okText='update'
        onCancel={() => setvisibleEdite(false)}
      >
        <Form form={form} onFinish={handleEdite}>
          {fieldEdited == 'Icd10Code' ? (
            <>
              <Form.Item name={fieldEdited} initialValue={fieldValue}>
                <Icdsearch onSelect={handleSelect} />
              </Form.Item>
              <FetchfromIcdBottom
                handleFinish={handleCodeFetching}
                icd={codeFetched[0]}
                mode='code'
              >
                fetch data from Icd10
              </FetchfromIcdBottom>
            </>
          ) : (
            <Form.Item name={fieldEdited} initialValue={fieldValue}>
              <JoditEditor value='' />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetIcds: (page) => dispatch(getIcds(page)),
    show: (icd) => dispatch(show(icd))
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.icd11.loading,
    error: state.icd11.error,
    total: state.icd11.total,
    page: state.icd11.page
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IcdsTable);
