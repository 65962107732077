import * as actionTypes from './types';
import axios from 'axios';
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const auth = (data) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = { ...data };
    let url = '/u-consultation/auth/login';
    axios
      .post(url, authData)
      .then((response) => {
        console.log(response);
        localStorage.setItem('token', response.data.data);
        localStorage.setItem('userId', response.data.accountId);
        dispatch(authSuccess(response.data.data, response.data.localId));
        window.location.replace('/ICD10');
      })
      .catch((err) => {
        dispatch(authFail(err.response.data.message));
      });
  };
};

export const authRedirect = (path) => {
  return {
    type: actionTypes.AUTH_REDIRECT,
    path: path
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const userId = localStorage.getItem('userId');
      dispatch(authSuccess(token, userId));
    }
  };
};

export const forgetPassword = () => {
  return {
    type: actionTypes.FORGET_PASSWORD
  };
};
