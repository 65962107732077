import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Avatar, Select, Tag, Badge, List } from 'antd';
import RegiondataWidget from '../../../../shared-components/RegiondataWidget';
import DonutChartWidget from '../../../../shared-components/DonutChartWidget';
import Flex from '../../../../shared-components/Flex';
import ChartWidget from '../../../../shared-components/ChartWidget';
import NumberFormat from 'react-number-format';
import ApexChart from 'react-apexcharts';
import { apexSparklineChartDefultOption, COLORS } from '../../../../constants/ChartConstant';
import utils from '../../../../utils';
import {
  FacebookFilled,
  TwitterSquareFilled,
  LinkedinFilled,
  YoutubeFilled,
  DribbbleSquareFilled
} from '@ant-design/icons';
import {
  conbinedSessionData,
  regionData,
  sessionColor,
  sessionData,
  sessionLabels
} from './AnalyticDashboardData';
import Axios from 'axios';
import { randomColor } from 'randomcolor';
const socialMediaReferralIcon = [
  <FacebookFilled style={{ color: '#1774eb' }} />,
  <TwitterSquareFilled style={{ color: '#1c9deb' }} />,
  <YoutubeFilled style={{ color: '#f00' }} />,
  <LinkedinFilled style={{ color: '#0077b4' }} />,
  <DribbbleSquareFilled style={{ color: '#e44a85' }} />
];

const { Option } = Select;

export const AnalyticDashboard = () => {
  const [locations, setLocation] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUser] = useState(0);
  useEffect(() => {
    Axios({
      method: 'GET',
      url: '/u-consultation/ude-user/count/user_location'
    }).then((res) => {
      const countries = res.data[0].location_details.map((country) => {
        return { ...country, color: randomColor() };
      });
      setLocation(countries);
    });

    Axios({
      method: 'GET',
      url: '/u-consultation/ude-user/count/users'
    }).then((res) => {
      const usersobj = res.data.map((item) => {
        const [key] = Object.entries(item);
        return { value: key[1], color: randomColor(), label: key[0] };
      });
      console.log(usersobj);
      setUsers(usersobj);
    });

    Axios({
      method: 'GET',
      url: '/analytics/count/activeUser'
    }).then((res) => {
      setActiveUser(res.data.count);
    });

    setInterval(() => {
      Axios({
        method: 'GET',
        url: '/analytics/count/activeUser'
      }).then((res) => {
        setActiveUser(res.data.count);
      });
    }, 30000);
  }, []);
  console.log(users.map((item) => item.color));
  const rederRegionTopEntrance = (
    <div className='mb-4'>
      <div className='d-flex align-items-start' style={{ flexDirection: 'column' }}>
        <h4 className='mb-0 ml-2 font-weight-bold'>
          Top country is ({' '}
          {locations.length > 0 &&
            locations.find(
              (country) =>
                country.count ===
                Math.max.apply(
                  Math,
                  locations.map(function (o) {
                    return o.count;
                  })
                )
            ).country}{' '}
          )
          {Math.max.apply(
            Math,
            locations.map(function (o) {
              return o.count;
            })
          )}{' '}
          user
        </h4>
      </div>
    </div>
  );

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xxl={18}>
          <RegiondataWidget
            title='user by region'
            data={locations}
            content={rederRegionTopEntrance}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xxl={6}>
          <DonutChartWidget
            series={users.map((item) => item.value)}
            labels={users.map((item) => item.label)}
            title='users'
            customOptions={{ colors: users.map((item) => item.color) }}
            extra={
              <Row justify='center'>
                <Col xs={20} sm={20} md={20} lg={24}>
                  <div className='mt-4 mx-auto' style={{ maxWidth: 200 }}>
                    {users.map((elm) => (
                      <Flex
                        alignItems='center'
                        justifyContent='between'
                        className='mb-3'
                        key={elm.label}
                      >
                        <div>
                          <Badge color={elm.color} />
                          <span className='text-gray-light'>{elm.label}</span>
                        </div>
                        <span className='font-weight-bold text-dark'>{elm.value}</span>
                      </Flex>
                    ))}
                  </div>
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
      <Row gutter={16}>
        {/* <Col xs={24} sm={24} md={24} lg={24} xxl={6}>
          <DonutChartWidget
            series={[
              users.length > 0 && users[0].value + users[1].value - activeUsers,
              activeUsers
            ]}
            labels={['none active', 'active']}
            title='users'
            customOptions={{ colors: ['#bdb8b8', '#00b67a'] }}
            extra={
              <Row justify='center'>
                <Col xs={20} sm={20} md={20} lg={24}>
                  <div className='mt-4 mx-auto' style={{ maxWidth: 200 }}>
                    <Flex alignItems='center' justifyContent='between' className='mb-3'>
                      <div>
                        <Badge color='#00b67a' />
                        <span className='text-gray-light'>Active user</span>
                      </div>
                      <span className='font-weight-bold text-dark'>{activeUsers}</span>
                    </Flex>
                    <Flex alignItems='center' justifyContent='between' className='mb-3'>
                      <div>
                        <Badge color='#bdb8b8' />
                        <span className='text-gray-light'>inActive user</span>
                      </div>
                      <span className='font-weight-bold text-dark'>
                        {users.length > 0 && users[0].value + users[1].value - activeUsers}
                      </span>
                    </Flex>
                  </div>
                </Col>
              </Row>
            }
          />
        </Col> */}
        {/* <Col xs={24} sm={24} md={24} lg={12} xxl={6}>
          <Card title='Most visited pages'>
            <List
              itemLayout='horizontal'
              dataSource={pagesViewData}
              renderItem={(item) => (
                <List.Item>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <div>
                      <h4 className='font-weight-bold mb-0'>{item.title}</h4>
                      <span className='text-muted'>{item.url}</span>
                    </div>
                    <div>
                      <Tag color='blue'>
                        <span className='font-weight-bold'>
                          <NumberFormat
                            value={item.amount}
                            thousandSeparator={true}
                            displayType='text'
                          ></NumberFormat>
                        </span>
                      </Tag>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Col> */}
        {/* <Col xs={24} sm={24} md={24} lg={12} xxl={6}>
          <Card title='Social media referrals'>
            <List
              itemLayout='horizontal'
              dataSource={socialMediaReferralData}
              renderItem={(item, index) => (
                <List.Item>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='d-flex align-items-center'>
                      <span className='font-size-xxl'>{socialMediaReferralIcon[index]}</span>
                      <div className='ml-3'>
                        <h4 className='font-weight-bold mb-0'>{item.title}</h4>
                        <span className='text-muted'>
                          Total: <span className='font-weight-bold'>{item.amount}</span>
                        </span>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <ApexChart
                        options={{
                          ...apexSparklineChartDefultOption,
                          ...{ colors: [utils.getSignNum(item.percentage, COLORS[1], COLORS[2])] }
                        }}
                        series={item.data}
                        height={25}
                        width={50}
                      />
                      <span className='ml-3 font-weight-bold'>{item.percentage}%</span>
                      <span
                        className={`ml-1 ${utils.getSignNum(
                          item.percentage,
                          'text-success',
                          'text-danger'
                        )}`}
                      >
                        {utils.getSignNum(
                          item.percentage,
                          <ArrowUpOutlined />,
                          <ArrowDownOutlined />
                        )}
                      </span>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Col> */}
        {/* <Col xs={24} sm={24} md={24} lg={24} xxl={12}>
          <ChartWidget
            series={uniqueVisitorsData.series}
            xAxis={uniqueVisitorsData.categories}
            title='Unique Visitors'
            height={410}
            type='bar'
            customOptions={{
              colors: [COLORS[1], COLORS[0]]
            }}
            extra={
              <Select
                defaultValue='week'
                size='small'
                style={{ width: 120 }}
                onChange={handleVisitorsChartChange}
              >
                <Option value='day'>Day</Option>
                <Option value='week'>Week</Option>
                <Option value='month'>Month</Option>
              </Select>
            }
          />
        </Col> */}
      </Row>
    </>
  );
};

export default AnalyticDashboard;
