import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';
import IcdsTable from './formulaTable';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';
import UsersForm from './addForm';

import { usePageData } from '../../../hooks/usePage';
import { getIcds, addingIcd, closeModal, addIcd } from '../../../redux/icd/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Axios from 'axios';

const FormulasPage = (props) => {
  const pageData: IPageData = {
    title: `formula / page ${props.page}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'formula'
      }
    ]
  };
  const [formuls, setFormulas] = useState();
  usePageData(pageData);
  useEffect(() => {
    Axios({
      method: 'GET',
      url: 'formula'
    }).then((res) => {
      setFormulas(res.data);
    });
  }, []);
  const history = useHistory();
  return (
    <>
      <IcdsTable formuls={formuls} />
      <PageAction onClick={() => history.push('/AddingFormula')} icon='icofont-contact-add' />
    </>
  );
};
export default FormulasPage;
