import * as actionType from './actiontype';
import {
  SET_USERS,
  FAIL_ADD,
  FAIL_SET,
  SUCCESSED_ADD,
  ADDING_USER,
  CLOSE_MODALE,
  SHOW_USER,
  EDITING_USER
} from './types';

const initState = {
  udeUsers: [],
  curruntUser: [],
  addingUser: false,
  loading: true,
  error: null,
  editing: false,
  total: null,
  role: 'clinic'
};

export const udeUserReducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_UDE_USERS:
      return { ...state, udeUsers: action.users, loading: false, total: action.total };
    case actionType.FAIL_SET_USERS:
      return {
        ...state,
        loading: false,
        error: 'please check your connection and try again'
      };
    case actionType.ADDING_UDE_USER:
      return {
        ...state,
        addingUser: true
      };
    case actionType.CLOSE_MODALE_UDE_USER:
      return {
        ...state,
        addingUser: false,
        error: null
      };
    case actionType.SUCCESSED_ADD_UDE_USER:
      return {
        ...state,
        addingUser: false,
        error: null
      };
    case actionType.FAIL_ADD_UDE_USER:
      return {
        ...state,
        error: action.error
      };

    case SHOW_USER:
      return {
        ...state,
        editing: true
      };
    default:
      return state;
  }
};
