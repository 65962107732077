import { ADD_ADMIN, DELETE_ADMIN, EDIT_ADMIN, SET_ADMINS , FAIL_ADD_ADMIN , OPEN_MODAL , CLOSE_MODAL , SUCCESS_ADD_ADMIN} from './types';

const initialState ={
  admins : [],
  total : null,
  showAdding : false,
  message : null
};

export const adminsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_ADMINS: {
      return {
        ...state ,
        admins : action.admins,
        total : action.total
      }
    }
    case SUCCESS_ADD_ADMIN:
      return{
        ...state,
        message : 'you success add this admin'
      }
    case FAIL_ADD_ADMIN :
      return{
        ...state,
        message : action.error
      }
    case OPEN_MODAL :
      return {
        ...state,
        showAdding : true
      }
    case CLOSE_MODAL :
      return {
        ...state,
        showAdding: false,
        message : null
      }
    default: {
      return state;
    }
  }
}

