import React, { useState, useEffect } from 'react';

import { Modal, Select } from 'antd';
import UsersTable from './udeUsersTable';

import { IPageData } from '../../../interfaces/page';
import PageAction from '../../../layout/components/page-action/PageAction';
import UsersForm from './udeUserForm';

import { usePageData } from '../../../hooks/usePage';
import { getUsers, addinguser, closeModal, addUser } from '../../../redux/udeUser/action';
import { connect } from 'react-redux';
const pageData: IPageData = {
  title: 'users',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Medicine',
      route: 'default-dashboard'
    },
    {
      title: 'users'
    }
  ]
};
const Option = Select.Option;
const UdeUsersPage = (props) => {
  const [role, setRole] = useState(props.role);
  usePageData(pageData);
  useEffect(() => {
    props.onGetusers(1, role);
  }, [role]);
  const onChangeRole = (value) => {
    console.log(value);
    setRole(value);
  };
  const onAddUser = (user) => props.onAddUser(user);
  return (
    <>
      <UsersTable users={props.users} />
      <PageAction onClick={props.onAddingUser} icon='icofont-contact-add' />

      <Modal
        visible={props.addingUser}
        onCancel={props.closeModal}
        title={<h3 className='title'>Add user</h3>}
        destroyOnClose
        footer={null}
      >
        <UsersForm onSubmit={onAddUser} onCancel={props.closeModal} submitText='Add user' />
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    addingUser: state.users.addingUser,
    users: state.udeUser.udeUsers,
    role: state.users.role
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetusers: (page) => dispatch(getUsers(page)),
    onAddingUser: () => dispatch(addinguser()),
    closeModal: () => dispatch(closeModal()),
    onAddUser: (user) => dispatch(addUser(user))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UdeUsersPage);
