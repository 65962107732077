export const Icd11Keys = [
  'GeneralSymptoms',
  'SpecificSymptoms',
  'RiskIndex',
  'Icd10Codes',
  'ListOfReferences',
  '_id',
  'ICDVersion',
  'System',
  'ParentBillableCode',
  'ChildBillableCode',
  'DiagnosisVariances',
  'ParentDiagnosis',
  'ParentDiagnosisVariances',
  'MicroOrganisms',
  'ChildAssociatedCode',
  'ClincalSigns',
  'LaboratoryDiagnosis',
  'RadiologyDiagnosis',
  'DiagnosticTest',
  'WhenToSeeDoctor',
  'LaboratoryInvestigations',
  'RadiologyScans',
  'PlanOfCare',
  'RecommendedMedications',
  'RecommendedTherapy',
  'MedicalAndNursingRecommendations',
  'PrecautionsAndPrevention',
  'AssociatedMedicalConditions',
  'ChronicDisease',
  'Accident',
  'Poisoning',
  'PregnancySpecialPrecautions',
  'Male',
  'Female',
  'fetus_first',
  'fetus_secand',
  'fetus_third',
  'fetus_post',
  'newborn',
  'm1_y5',
  'y5_15',
  'y15_25',
  'y25_45',
  'y45_65',
  'above65y',
  'Chapter',
  'ChapterdetailsLevelOne',
  'SectionLevelTwo',
  'SectionDetailsLevelTwo',
  'TopicLevelThree',
  'TopicDetailsLevelThree',
  'ParentCategory',
  'ParentCode',
  'ParentDetails',
  'ParentAssociatedCode',
  'ChildCategory',
  'ChildCode',
  'Diagnosis',
  'ChildDitails',
  'RelatedSpecialty'
];

export const emptyIcd11 = {
  _id: null,
  ICDVersion: null,
  System: null,
  ParentBillableCode: null,
  Icd10Codes: null,
  ChildBillableCode: null,
  DiagnosisVariances: null,
  ParentDiagnosis: null,
  ParentDiagnosisVariances: null,
  MicroOrganisms: null,
  ChildAssociatedCode: null,
  GeneralSymptoms: null,
  SpecificSymptoms: null,
  ClincalSigns: null,
  LaboratoryDiagnosis: null,
  RadiologyDiagnosis: null,
  DiagnosticTest: null,
  WhenToSeeDoctor: null,
  LaboratoryInvestigations: null,
  RadiologyScans: null,
  PlanOfCare: null,
  RecommendedMedications: null,
  RecommendedTherapy: null,
  MedicalAndNursingRecommendations: null,
  PrecautionsAndPrevention: null,
  AssociatedMedicalConditions: null,
  RiskIndex: null,
  ChronicDisease: null,
  Accident: null,
  Poisoning: null,
  PregnancySpecialPrecautions: null,
  Male: null,
  Female: null,
  fetus_first: null,
  fetus_secand: null,
  fetus_third: null,
  fetus_post: null,
  newborn: null,
  m1_y5: null,
  y5_15: null,
  y15_25: null,
  y25_45: null,
  y45_65: null,
  above65y: null,
  ListOfReferences: null,
  Chapter: null,
  ChapterdetailsLevelOne: null,
  SectionLevelTwo: null,
  SectionDetailsLevelTwo: null,
  TopicLevelThree: null,
  TopicDetailsLevelThree: null,
  ParentCategory: null,
  ParentCode: null,
  ParentDetails: null,
  ParentAssociatedCode: null,
  ChildCategory: null,
  ChildCode: null,
  Diagnosis: null,
  ChildDitails: null,
  RelatedSpecialty: null
};
