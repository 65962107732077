import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputNumber,
  Form,
  Collapse,
  Modal,
  notification,
  Row,
  Select,
  Radio,
  Slider,
  Card,
  Divider,
  Col
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useHistory } from 'react-router';
import { editIcd } from '../../../redux/icd/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import FullPageLoading from '../../components/FullPageLoading';
import { Parser } from 'expr-eval';
import ChoiceForm from './choiceForm';

import Axios from 'axios';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Specialtyinput from '../../components/specialtyInput/specialtyinput';
const { Panel } = Collapse;
const Option = Select;
const emptyformula = {
  _id: null,
  formula: null,
  formulaKeys: [
    {
      name: null,
      key: null,
      type: null,
      maxValue: '',
      minValue: '',
      choices: [
        {
          title: null,
          name: null,
          value: ''
        }
      ]
    }
  ],
  title: null,
  description: null,
  resultDescription: null,
  resultMeasuringUnit: null,
  formulaDetails: null,
  speciality: null,
  hashtags: null
};
const FormulaPrview = () => {
  const pageData: IPageData = {
    title: `formula`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Medicine',
        route: 'default-dashboard'
      },
      {
        title: 'formula'
      }
    ]
  };
  const [formula, setFormula] = useState(emptyformula);
  const [loading, setloading] = useState(true);
  const [reuslt, setResult] = useState();
  const params: { id: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    FormulaForm.validateFields();
  });
  useEffect(() => {
    // FormulaForm.isFieldsTouched() && setTested(false);
    axios({
      method: 'GET',
      url: `formula/${params.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log('formula', res.data);
      setFormula({ ...res.data });
      FormulaForm.setFieldsValue(res.data);
      setloading(false);
    });
  }, []);

  usePageData(pageData);
  const [FormulaForm] = useForm();
  const [testForm] = useForm();
  const [tested, setTested] = useState(true);
  const handleSubmit = () => {
    //     testForm.validateFields();
    //     console.log(testForm.validateFields());
    const { ...values } = testForm.getFieldsValue();
    const parser = new Parser();

    function calc_inc(pre_prop, LR) {
      const pre_odds = Math.round(pre_prop / (100 - pre_prop));
      const post_odds = pre_odds * LR;
      const post_prop = Math.floor((post_odds / (post_odds + 1)) * 100);
      const result = post_prop - pre_prop;
      return result;
    }

    parser.functions.calc_total = function (lr_list: any, pre_prop) {
      var total_inc = 0;
      for (let lr = 0; lr < lr_list.length; lr++) {
        total_inc += calc_inc(pre_prop, lr_list[lr]);
      }
      return pre_prop + total_inc;
    };

    if (!Object.keys(values).some((k) => !values[k])) {
      const parsedFormula = parser.parse(FormulaForm.getFieldValue('formula'));
      const value = parsedFormula.evaluate(values);
      if (value) {
        setResult(value);
      } else {
        notification.warning({
          placement: 'bottomRight',
          message: `some thing is wrong`
        });
      }
    }
  };
  const [sliderValue, setsliderValue] = useState();
  const handleSlideronChange = (value) => {
    setsliderValue(value);
  };
  return (
    <>
      {loading ? (
        <>
          <FullPageLoading />
        </>
      ) : (
        <>
          <h2>description</h2>
          <Divider />

          <div
            dangerouslySetInnerHTML={{ __html: formula.description }}
            className='Icd-table-colume'
          ></div>

          <h2>{formula.title}</h2>
          <Divider />

          <h2>formula</h2>
          <Divider />
          {formula.formula}
          <Form form={testForm} onFinish={handleSubmit} onFieldsChange={handleSubmit}>
            {formula.formulaKeys.map((item) => {
              return (
                <Form.Item
                  name={item.key}
                  label={item.name}
                  rules={[{ required: true, message: `Please input the ${item.name}!` }]}
                >
                  {item.type === 'range' ? (
                    <Slider
                      min={parseInt(item.minValue)}
                      max={parseInt(item.maxValue)}
                      onChange={handleSlideronChange}
                      tooltipVisible
                      value={typeof sliderValue === 'number' ? sliderValue : 0}
                    />
                  ) : (
                    <Radio.Group>
                      {item.choices.map((item) => {
                        return <Radio value={item.value}>{item.name}</Radio>;
                      })}
                    </Radio.Group>
                  )}
                </Form.Item>
              );
            })}

            {/* <Button type='primary' htmlType='submit'>
              show result
            </Button> */}
          </Form>
          <div
            style={{
              border: '1px solid #520974',
              display: 'flex',
              alignItems: 'center',
              width: '400px',
              padding: '10px',
              height: '100px'
            }}
          >
            <h3>result is :</h3>
            <h3>{`${reuslt ? reuslt : ''}  ${
              formula.resultMeasuringUnit ? formula.resultMeasuringUnit : ''
            }`}</h3>
          </div>
        </>
      )}
    </>
  );
};

export default FormulaPrview;
