import React from 'react';

import { Button, Form, Input, Switch, Alert } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import { connect } from 'react-redux';
import * as action from '../../redux/auth/action';

import PublicLayout from '../../layout/public/Public';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
// import { navigateHome } from '../../utils/naviagate-home';

const { Item } = Form;

const SignIn = (props) => {
  const [form] = useForm();

  const login = () => {
    form
      .validateFields()
      .then(() => props.onAuth({ type: 'PASSWORD', ...form.getFieldsValue() }))
      .catch(() => null);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/shared.svg`}>
      <h4 className='mt-0 mb-1'>Login form</h4>

      <p className='text-color-200'>Login to access your Account</p>
      {props.errormsg ? (
        <Alert type='error' message={props.errormsg} style={{ margin: '20px' }} closable />
      ) : null}
      <Form form={form} layout='vertical' className='mb-4'>
        <Item name='username' rules={[{ required: true, message: <>you cant let it </> }]}>
          <Input placeholder='Login' />
        </Item>
        <Item name='password' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Password' type='password' />
        </Item>

        <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Remember me</span>
        </div>

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Login
        </Button>
      </Form>
      <br />
      <p className='mb-1'>
        <Link to='/public/forgot-password'>Forgot password?</Link>
      </p>

      {/* <p>
        Don't have an account? <Link to='sign-up'>Sign up!</Link>
      </p> */}
    </PublicLayout>
  );
};
const mapStateToProps = (state) => {
  return {
    errormsg: state.auth.error
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (data) => dispatch(action.auth(data)),
    onSetAuthRedirectPath: () => dispatch(action.authRedirect('/'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
