import React from 'react';
import { Button, Input, Collapse, Form, Alert, InputNumber, Row, Col, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect, useDispatch } from 'react-redux';
import { addIcd } from '../../../redux/icd/actions';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { emptyIcd11, Icd11Keys } from '../common/icd11Types';
import JoditEditor from 'jodit-react';
import { AgeGroup, IcdKeys } from '../common/icd10typs';
import Axios from 'axios';
import { IcdCmKeys } from '../common/icdcmtypes';

const { Panel } = Collapse;
const { Item } = Form;

const { TextArea } = Input;

const AddIcdCmForm = ({ icd11 = emptyIcd11[0] }) => {
  const [form] = useForm();
  const handleSubmit = (values) => {
    form
      .validateFields()
      .then(() =>
        Axios({
          method: 'POST',
          url: `u-consultation/icd_cm`,
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          data: values
        }).then(() => {
          message.success('updated success');
        })
      )
      .catch(() => null);
  };
  const history = useHistory();
  const handleCancel = () => {
    history.goBack();
  };

  return (
    <>
      <Form form={form} onFinish={handleSubmit} initialValues={icd11} layout='vertical'>
        <Collapse>
          {IcdCmKeys.map((item) => {
            return (
              <Panel header={item} key={item}>
                <div className='form-group'>
                  {item === 'AgeGroup' ? (
                    <Form.Item>
                      <Input.Group compact>
                        <Collapse style={{ width: '100%' }}>
                          {AgeGroup.map((categoriy) => {
                            return (
                              <Panel header={categoriy} key={categoriy}>
                                <Form.Item name={['ageGroup', categoriy]} noStyle>
                                  <InputNumber
                                    max={1}
                                    min={0}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                    placeholder={categoriy}
                                    maxLength={1}
                                  />
                                </Form.Item>
                              </Panel>
                            );
                          })}
                        </Collapse>
                      </Input.Group>
                    </Form.Item>
                  ) : item === 'Male' ||
                    item === 'Female' ||
                    item === 'Poisoning' ||
                    item === 'Accident' ||
                    item === 'ChronicDisease' ||
                    item === 'RiskIndex' ? (
                    <Form.Item name={item} noStyle>
                      <InputNumber
                        max={item === 'RiskIndex' ? 10 : 1}
                        min={0}
                        style={{ width: '100%', marginBottom: '10px' }}
                        placeholder={item}
                        maxLength={1}
                      />
                    </Form.Item>
                  ) : (
                    <Item name={item}>
                      <JoditEditor value='' />
                    </Item>
                  )}
                </div>
              </Panel>
            );
          })}
        </Collapse>

        <div
          className='d-flex justify-content-between buttons-list settings-actions'
          style={{ width: '100%' }}
        >
          <Button type='link' onClick={handleCancel}>
            go back
          </Button>

          <Button type='primary' htmlType='submit'>
            add icd_cm
          </Button>
        </div>
      </Form>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.users.error
  };
};

export default connect(mapStateToProps)(AddIcdCmForm);
