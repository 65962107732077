import {SET_USERS , FAIL_ADD , FAIL_SET, SUCCESSED_ADD, ADDING_USER , CLOSE_MODALE ,EDITING_USER ,SHOW_USER} from './types';
import axios from 'axios'

export const setUsers = (users , total) => {
    return{
        type : SET_USERS,
        users : users,
        total  :total
    }
}
export const failSetUsers = () => {
    return{
        type : FAIL_SET,
    }
}
export const failAdd = (error) => {
    return{
        type : FAIL_ADD,
        error : error
    }
}
export const successedAdd = () => {
    return{
        type : SUCCESSED_ADD
    }
}
export const addinguser = () => {
    return{
        type : ADDING_USER
    }
}
export const closeModal = () => {
    return {
        type : CLOSE_MODALE
    }
}
export const  edit  = () => {
    return {
        type : CLOSE_MODALE,
    }
}
export const show = (user) => {
    return {
        type : SHOW_USER,
        user : user
    }
}

export const getUsers = (page , role) => {
    return dispatch => {
        axios({
            method : "GET",
            url : '/u-consultation/superadmin/list/users',
            params : {page : page, limit : 10 ,role : role},
            headers : {Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json",}
        }).then((resp)=> {
            console.log(resp)
            dispatch(setUsers(resp.data.docs , resp.data.totalDocs));
        }).catch((err) =>{
         dispatch(failSetUsers())
        })
    }
}
export const addUser = (data) => {
    return dispatch => {
        axios({
            method : "POST",
            url : '/u-consultation/superadmin/add/user',
            data : {...data},
            headers : {Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json",}
        }).then((resp)=> {
            dispatch(successedAdd())
        }).catch((err) => {
           dispatch(failAdd(err.response.data.message))
           console.log(err.response.data.message)
        })
    }
}
export const editUser = (id, data) => {
    return dispatch => {
        axios({
            method: 'PUT',
            url : `/u-consultation/superadmin/user/${id}`,
            data : {
                ...data
            },
            headers : {Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json",}
        }).then((res) => {
           dispatch(getUsers(1 , 'clinic'))
        })
    }
}
export const switchUser = (id , isActive) => {
    return dispatch => {
        axios({
            method: 'POST',
            url : "/u-consultation/superadmin/switch/user",
            data : {
                id : id,
                isActive :  isActive
            },
            headers : {Authorization: `Bearer ${localStorage.getItem("token")}`, "content-type": "application/json",}
        }).then((res) => {
            console.log(res)
            dispatch(getUsers)
        })
    }
}